/* eslint-disable use-isnan */
/* eslint-disable brace-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable no-duplicate-imports */
import React, {useEffect, useState} from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import {Box, DialogActions, DialogContent, FormControlLabel, Checkbox, DialogTitle, Divider, IconButton, Typography, FormControl, Grid, Select, MenuItem, Menu} from '@mui/material'
import {Close} from '@mui/icons-material'
import {decode as base64Decode} from 'base-64'
import styled from 'styled-components'
import {CustomTextBox} from '../../../../Inputs/index.js'
import {CustomButton} from '../../../../Inputs/index.js'
import AlertMessage from '../../../../AlertMessage/alertMessage.js'
import MyDiv from './blockMoveDialog.style.js'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})
const target = [
  {
    id: 1,
    name: 'After',

  },
  {
    id: 2,
    name: 'Before',

  },
]
const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))
export default function BlockMoveDialog(props) {

  const [inputValues, setInputValues] = useState({target: 'After'})
  const [flagged, setFlagged] = useState()
  const [userData, setUserData] = useState()
  const [message, setMessage] = useState(null)
  const [status, setStatus] = useState(null)

  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string

  const handleChange = (e) => {
    setInputValues({...inputValues, [e.target.name]: e.target.value})
  }


  const handleFlagged = (e) => {
    setFlagged(e.target.checked)
  }


  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    // dispatch(assetsListActions.clearMessageData())
  }

  useEffect(() => {
    let arr = []
    props.appraiserData?.map((item) => {
      if (item.location_id === props.rowData?.location_id) {
        arr.push(item)
      }
    })
    setUserData(arr[0])
  }, [])

  const handleDialog = () => {
    setInputValues({target: 'After'})
    props.handleDialog()
  }
  // useEffect(() => {
  //   handleDialog()
  // }, [])


  const handleMove = () => {
    let invalidBlockMove = false
    let invalidMoveTo = false

    let formData = inputValues.from.split(/[,-]+/)
    let formDataToSend = inputValues.from.split(',').filter((n) => n.trim() !== '')

    // Validate from data
    for (let av of formDataToSend) {
      if (av.trim() !== '' && !/^\d{1,10}(-\d{1,10})?$/.test(av)) {
        invalidBlockMove = true
        setMessage('Please enter valid data in Move.')
        setStatus('warning')
        return false
      }
    }

    if (formData.length > 0) {
      let lastItem = 0
      let minV = formData[0]
      let maxV

      for (let i = 0; i < formData.length; i++) {
        let item = formData[i].trim()
        if (item === '') {
          if (i === formData.length - 1) {
            invalidBlockMove = false
          }
          formData.splice(i, 1)
          continue
        }
        if (+item <= +lastItem || isNaN(item)) {
          invalidBlockMove = true
          setMessage('Please enter valid data in Move.')
          setStatus('warning')
          return false
        } else {
          lastItem = +item
          maxV = lastItem
          if (i === formData.length - 1) {
            invalidBlockMove = false
          }
        }
      }
      // ! commenting out for now - doesn't seem like we need this validation at all
      if (inputValues.to >= minV && inputValues.to <= maxV) {
        invalidMoveTo = true
        setMessage('Invalid move to')
        setStatus('warning')
        return false
      } else {
        invalidMoveTo = false
      }
    }

    // If validation passes, call handleMove
    let obj = {
      target: inputValues.target.toLowerCase(),
      from: inputValues.from,
      to: Number(inputValues.to),
      fromData: inputValues.from,
      projectId: props.rowData.project_id,
      project_id_crm: decodedProjectId,
      appraisers: props.appraiserData,
      selectedAsset: props.selectedAsset,
      min_ref_no: props.minRefNo,
      max_ref_no: props.maxRefNo,
      mergedLine: flagged,
      userdetails: {
        created_on: props.rowData.created_on,
        first_name: userData?.name,
        last_name: userData?.last_name,
        middle_name: userData?.middle_name,
        modified_on: props.rowData.modified_on,
        role: '1',
        status: 1,
        user_email: null,
        user_id: userData?.user_id,
        username: userData?.username,
      },
      user_id: props.rowData.user_id,
    }

    props.handleMove(obj)

    localStorage.setItem('scroll_ref_id', obj.to)
    localStorage.removeItem('type_id')
    setInputValues({target: 'After'})

    return true
  }

  const popup = (popupbox) => (
    <MyDiv>
      <Box className="dialog_box">
        <Box className="dialog_header">
          <DialogTitle className="dialog_title">  {props.item ? props.item.title : ''}</DialogTitle>
          <IconButton className="icon" onClick={handleDialog}><Close /></IconButton>
        </Box>
        <Divider />
        <DialogContent className="dialog_content">
          <Grid container columnSpacing={{md: 2, sm: 2}}>
            <Grid item md={6}>
              <Box className="input_box">
                <Typography className="subHeading">Move (e.g 2,4,16-20,25,28-32,36,37)</Typography>
                <FormControl size="small" fullWidth>
                  <CustomTextBox value={inputValues.from} onChange={handleChange} name="from" />
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Typography className="subHeading">Move to</Typography>
                <FormControl size="small" fullWidth>
                  <CustomTextBox type="number" value={inputValues.to} onChange={handleChange} name="to" />
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className="input_box">
                <Typography className="subHeading">Target</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    id="select_input"
                    className="select_value"
                    value={inputValues.target}
                    defaultValue="After"
                    onChange={handleChange}
                    name="target"
                    MenuProps={{...Menu}}
                  >
                    {target?.map((item) => {
                      return (
                        <StyledMenuItem key={item.id} value={item.name}>
                          {item.name}
                        </StyledMenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box className="input_box">
                <Typography className="subHeading">Merge Line</Typography>
                <FormControl size="small" fullWidth>
                  <FormControlLabel
                    className="checked_label"
                    control={<Checkbox onChange={(e) => handleFlagged(e)} checked={inputValues?.merge_line} />}
                  // label="Flagged"
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box className="d-flex btn-flex mb-20 mt-10">
            <CustomButton
              title="Move"
              variant="contained"
              className="btn_theme"
              onClick={handleMove}
              disabled={!inputValues?.from || !inputValues?.to || (inputValues?.from && inputValues?.from.length === 0 || +inputValues?.from === 0) || (inputValues?.to && +inputValues?.to === 0)}
            />
            <CustomButton
              title="Cancel"
              variant="contained"
              className="btn_theme"
              onClick={handleDialog}
            />
          </Box>
        </DialogActions>
      </Box>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
  return (
    <Dialog
      popupbox="openDialog"
      open={props.openDialog}
      TransitionComponent={Transition}
      onClose={handleDialog}
      fullWidth
    >
      {popup('openDialog')}
    </Dialog>
  )
}
