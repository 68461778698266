/* eslint-disable  */
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { Box, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography, FormControl, Grid, Select, MenuItem, Menu } from '@mui/material'
import { Close } from '@mui/icons-material'
import styled from 'styled-components'
// import {useSelector} from 'react-redux'
import { CustomTextBox } from '../../../../Inputs/index.js'
import { CustomButton } from '../../../../Inputs/index.js'
import AlertMessage from '../../../../AlertMessage/alertMessage.js'

// import {getProjectDataAction} from '../../../../../redux/reports/ReportActions'
// import {getAssetListDataAction} from '../../../../../redux/assets/AssetsActions'

import MyDiv from './reassignDialog.style.js'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})
// const assigner = [
//   {
//     id: 1,
//     name: 'Mark',
//   },
//   {
//     id: 2,
//     name: 'Jim',
//   },
//   {
//     id: 3,
//     name: 'Lucas',

//   },
//   {
//     id: 4,
//     name: 'Mark',

//   },
//   {
//     id: 5,
//     name: 'Adam',

//   },
// ]
const StyledMenuItem = styled(MenuItem)(() => ({
  '&.MuiMenuItem-root': {
    borderBottom: '1px solid #ccc',
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  '&.MuiMenuItem-root:first-child': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))
export default function ReassignDialog(props) {
  console.log(props.appraiserData, 'props.appraiserData')
  const [inputValues, setInputValues] = React.useState({})
  const [projectId, setProjectId] = useState()
  // const [appraiserData, setAppraiserData] = useState([])
  const [message, setMessage] = useState(null)
  const [status, setStatus] = useState(null)
  // const dispatch = useDispatch()

  // const reportState = useSelector((state) => state.report)
  // const assetsState = useSelector((state) => state.assets)
  const [isDataValid, setIsValidData] = useState(false)

  // useEffect(() => {
  //   dispatch(getProjectDataAction())
  // }, [dispatch])
  useEffect(() => {
    let projectID = localStorage.getItem('project_id')
    setProjectId(projectID)
  }, [])
  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    // dispatch(assetsListActions.clearMessageData())
  }

  // useEffect(() => {
  //   setAppraiserData(assetsState?.getAssetListData?.getAppraiserData)
  // }, [assetsState?.getAssetListData?.getAppraiserData])

  // useEffect(() => {
  //   let appraiserData = {
  //     action: 'getAppraiser',
  //     project_id: projectId,
  //   }
  //   dispatch(getAssetListDataAction(appraiserData))
  // }, [dispatch, projectId])

  const handleChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })
  }

  const handleDialog = () => {
    setInputValues({})
    props.handleDialog()
  }

  const handleReassign = () => {
    if (inputValues?.appraiser && inputValues?.assign && inputValues?.through) {
      let appraiserValue = props.appraiserData.filter((element, index) => element.name === inputValues.appraiser)
      let obj = {
        action: 'reassignAppraiserToAsset',
        appraiser: {
          last_name: appraiserValue[0].last_name,
          location_id: props.rowData.location_id,
          middle_name: appraiserValue[0].middle_name,
          name: inputValues.appraiser,
          user_id: appraiserValue[0].user_id,
          username: appraiserValue[0].username,
        },
        assign: +inputValues.assign,
        projectId: +projectId,
        through: +inputValues.through,
      }
      props.handleReassign(obj)
      setInputValues({})
    } else {
      setMessage('Fill all the fields')
      setStatus('warning')
    }
  }
  // const uniqueUsernames = new Set()
  // const uniqueAppraiserData = []

  // appraiserData?.forEach((entry) => {
  //   if (!uniqueUsernames.has(entry.username)) {
  //     uniqueUsernames.add(entry.username)
  //     uniqueAppraiserData.push(entry)
  //   }
  // })
  // let locationid = localStorage.getItem('location_id_home')
  // let locationId1 = localStorage.getItem('updatedLocationId')
  // let finalLocation = locationid ?? locationId1
  // let finalAppraiser = appraiserData?.filter(({location_id}) => +locationid === +location_id)
  useEffect(() => {
    let isValid = false
    isValid = (!inputValues?.through || !inputValues?.appraiser || !inputValues?.assign || +inputValues?.assign > +inputValues?.through ||
      +inputValues?.assign < +props?.assetResponse?.min_ref_no || +inputValues?.through < +props?.assetResponse?.min_ref_no + inputValues?.assign >
      +props?.assetResponse?.max_ref_no || +inputValues?.through > +props?.assetResponse?.max_ref_no)
    setIsValidData(isValid)
  }, [inputValues])
  const popup = (popupbox) => (
    <MyDiv>
      <Box className="dialog_box">
        <Box className="dialog_header">
          <DialogTitle className="dialog_title">Reassign a Block of Assets</DialogTitle>
          <IconButton className="icon" onClick={handleDialog}><Close /></IconButton>
        </Box>
        <Divider />
        <DialogContent className="dialog_content">
          <Grid item md={4}>
            <Box className="input_box">
              <Typography className="subHeading">Valid reference numbers are <strong>{props?.assetResponse?.min_ref_no}</strong> to <strong>{props?.assetResponse?.max_ref_no}</strong></Typography>
              <Typography className="subHeading">Assign</Typography>
              <FormControl size="small" fullWidth>
                <CustomTextBox type="number" value={inputValues.assign} onChange={handleChange} name="assign" />
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography className="subHeading">Through</Typography>
              <FormControl size="small" fullWidth>
                <CustomTextBox type="number" value={inputValues.through} onChange={handleChange} name="through" />
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className="input_box">
              <Typography className="subHeading">Reassign to</Typography>
              <FormControl size="small" fullWidth>
                <Select
                  id="select_input"
                  className="select_value"
                  value={inputValues.appraiser ?? 0}
                  placeholder="Select Appraiser"
                  onChange={handleChange}
                  name="appraiser"
                  MenuProps={{ ...Menu }}
                >
                  <StyledMenuItem value={0}>Select Appraiser</StyledMenuItem>
                  {/* {locationid ? finalAppraiser?.map((item, index) => {
                    return (
                      <StyledMenuItem key={index} value={item.name}>
                        {item.name ? item.name : 'null'}
                      </StyledMenuItem>
                    )
                  }) :
                    uniqueAppraiserData?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.name}>
                          {item.name ? item.name : 'null'}
                        </StyledMenuItem>
                      )
                    })
                  } */}
                  {
                    props.appraiserData?.map((item, index) => {
                      return (
                        <StyledMenuItem key={index} value={item.name}>
                          {item.name ? item.name : 'null'}
                        </StyledMenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box className="d-flex btn-flex mb-20 mt-10">
            <CustomButton
              title="Reassign"
              variant="contained"
              className="btn_theme"
              onClick={handleReassign}
              disabled={isDataValid}
            />
            <CustomButton
              title="Cancel"
              variant="contained"
              className="btn_theme"
              onClick={handleDialog}
            />
          </Box>
        </DialogActions>
      </Box>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
  return (
    <Dialog
      popupbox="openDialog"
      open={props.openDialog}
      TransitionComponent={Transition}
      onClose={handleDialog}
      fullWidth
    >
      {popup('openDialog')}
    </Dialog>
  )
}
