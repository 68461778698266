/* eslint-disable no-duplicate-imports */
import React from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import {Box, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography} from '@mui/material'
import {Close} from '@mui/icons-material'
import {CustomTextBox} from '../../../../Inputs/index.js'
import {CustomButton} from '../../../../Inputs/index.js'
import MyDiv from './editPopup.style.js'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function EditPopup(props) {
  // useEffect(() => {
  //   if (props.updateArea === props.areaName) {
  //     setEnable(true)
  //   } else {
  //     setEnable(false)
  //   }
  // }, [props.updateArea])
  const popup = (popupbox) => (
    <MyDiv>
      <Box className="dialog_box">
        <Box className="dialog_header">
          <DialogTitle className="dialog_title"> Change Area Name</DialogTitle>
          <IconButton className="icon" onClick={props.handleEditArea}><Close /></IconButton>
        </Box>
        <Divider />
        <DialogContent className="dialog_content">
          <Box className="area_input">
            <Box>
              <Typography className="label"> Old Area Name</Typography>
              <CustomTextBox value={props.areaName} disabled />
            </Box>
            <Box>
              <Typography className="label"> New Area Name</Typography>
              <CustomTextBox maxLength="250" value={props.newAreaName} onChange={props.handleChangeAreaName} />
              <Typography className="label"> Max 250 character</Typography>

            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className="d-flex btn-flex mb-20 mt-10">
            <CustomButton
              title="Update Name"
              variant="contained"
              className="btn_theme"
              onClick={props.handleUpdateArea}
              // eslint-disable-next-line eqeqeq
              disabled={!props.newAreaName || props.newAreaName == props.areaName}
            />
            <CustomButton
              title="Cancel"
              variant="contained"
              className="btn_theme"
              onClick={props.handleEditArea}
            />
          </Box>
        </DialogActions>
      </Box>
    </MyDiv>
  )
  return (
    <Dialog
      popupbox="openDialog"
      open={props.areaName}
      TransitionComponent={Transition}
      onClose={props.handleEditArea}
      fullWidth
    >
      {popup('openDialog')}
    </Dialog>
  )
}
