import React, {useEffect, useState} from 'react'
import {Box, Checkbox, FormControlLabel, Typography} from '@mui/material'
import {decode as base64Decode} from 'base-64'
import {AlertMessage, CustomButton, CustomTextBox, Loader} from '../../../components'
import {getFASReport, getLocations, getPhotosByAsset, getProjAssets, getProjectData, getProjectReport} from '../../../services/reportServices'
import {getRefDataCommon} from '../../../utils/Report.utils'
import {getFormattedDateTime} from '../../../utils/Utils'
import MyDiv from './photoReports.style'

export default function PhotoReports() {

  const [startingPage, setStartingPage] = useState(1)
  const [displayRefNumber, setDisplayRefNumber] = useState(false)
  const [projectReport, setProjectReport] = useState(null)
  const [photosByAsset, setPhotosByAsset] = useState(null)
  const [projAssets, setProjAssets] = useState(null)
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [dateTimeValue, setDateTimeValue] = useState(getFormattedDateTime().date)


  let projectID = localStorage.getItem('project_id') // this is integer project id
  const userId = localStorage.getItem('userId')
  let projectIDCrm = localStorage.getItem('project_id_crm')
  let decodedProjectId = base64Decode(projectIDCrm) // This is a Base64 encoded string with crm id
  useEffect(() => {
    Promise.all([
      getLocations(true, decodedProjectId).then((res) => {console.log(res, 'locationData')}),
      getProjectData(decodedProjectId).then(({data}) => {console.log(data, 'projectData')}),
      getProjectReport('reports', decodedProjectId).then((data) => setProjectReport(data)),
      getPhotosByAsset(startingPage, projectID).then((data) => setPhotosByAsset(data.data)),
      getProjAssets({
        sortCol: 'area_order',
        sortOrder: 'asc',
        action: 'getAssets',
        projectId: projectID,
      }).then((data) => setProjAssets(data?.data)),
    ]).then(() => setLoading(false))

  }, [])

  const generatePdfList = (type, format) => (e) => {
    if (projAssets) {
    // add location_label key in projectReport json
      if (projectReport && projectReport.data && projectReport.data.location) {
        projectReport.data.location.forEach((location) => {
          location.location_label = location.street
        })
      }
      setLoading(true)
      const filterOptions = { // !done
        sortCol: 'area_order',
        sortOrder: 'asc',
        action: 'getAssets',
      }
      const allData = getRefDataCommon(projAssets, filterOptions)
      const filteredData = allData.refData.filter((item) => item.ref_no !== undefined)
      const requestData = {
        puser_id: +userId,
        project_id: +projectID,
        reportType: type,
        reportFormat: format,
        refData: filteredData,
        reportData: photosByAsset.asset_photos,
        values_total: allData.values_total,
        currency_symbol: allData.currency_symbol,
        locations: projectReport.data.location,
        start_page: startingPage,
        reportDateTime: `${dateTimeValue}${getFormattedDateTime().time}`,
      }
      requestData.refShow = displayRefNumber
      getFASReport(requestData).then((res) => {
        setLoading(false)
        if (res.data && res.data.path) {
          let path = `${process.env.REACT_APP_BASE_URL}storage/reports/${res.data.path}`
          window.open(path)
        } else {
          setMessage('No images nominated in this project.')
          setStatus('warning')
        }
      }).catch((err) => {
        setLoading(false)
        setMessage(`Something went wrong - ${err?.message}`)
        setStatus('warning')
      })
    }

  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
  }

  return (
    <MyDiv>
      { loading &&
        <div>
          <Loader />
        </div>
      }
      <Box className="page_heading">
        <Typography>Photo Reports</Typography>
      </Box>
      <Box className="wrapper_box">
        <FormControlLabel
          className="checked_label"
          control={<Checkbox checked={displayRefNumber} onChange={(e) => setDisplayRefNumber(e.target.checked)} />}
          label="Display Reference No."
        />
        <Box className="input_box">
          <Typography>Date</Typography>
          <input aria-label="Date" type="date" value={dateTimeValue} style={{padding: '5px'}} onChange={(e) => setDateTimeValue(e.target.value)} />
        </Box>
        <Box className="input_box">
          <Typography>Starting Page</Typography>
          <CustomTextBox name="page" className="page_input" value={startingPage} onChange={(e) => setStartingPage(e.target.value)} />
        </Box>
        <Box>
          <CustomButton
            title="Generate Photo PDF"
            variant="contained"
            onClick={generatePdfList('photo_reports', 'pdf')}
            className="btn_theme"
          />
        </Box>
      </Box>
      <AlertMessage
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}
