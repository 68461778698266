/* eslint-disable */

export const generateKey = (key) => {
  switch (key) {
    case "FLV":
      return "Forced Liquidation Value";
    case "OLV":
      return "Orderly Liquidation Value";
    case "FMV":
      return "Fair Market Value";
    case "FMVIP":
      return "Fair Market Value - In Place";
    case "FMVR":
      return "Fair Market Value - Removal";
    case "FAS":
      return "Fair Value";
    case "NFLV":
      return "Net Forced Liquidation Value";
    case "NOLV":
      return "Net Orderly Liquidation Value";
    case "OLVIP":
      return "Orderly Liquidation Value - In Place";
    case "RCN":
      return "Replacement Cost New";
    case "CV":
      return "Custom Value";
    case "FV":
      return "FV";
    case "year":
      return "year";
    default:
      break;
  }
};
export const generateKeyFromName = (key) => {
  switch (key) {
    case "Forced Liquidation Value":
      return "FLV";
    case "Orderly Liquidation Value":
      return "OLV";
    case "Fair Market Value":
      return "FMV";
    case "Fair Market Value - In Place":
      return "FMVIP";
    case "Fair Market Value - Removal":
      return "FMVR";
    case "Fair Value":
      return "FAS";
    case "Net Forced Liquidation Value":
      return "NFLV";
    case "Net Orderly Liquidation Value":
      return "NOLV";
    case "Orderly Liquidation Value - In Place":
      return "OLVIP";
    case "Replacement Cost New":
      return "RCN";
    case "Custom Value":
      return "CV";
    case "year":
      return "year";
    default:
      break;
  }
};

export const generateSelected = (key) => {
  switch (key) {
    case "FLV":
      return "FLV";
    case "OLV":
      return "OLV";
    case "FMV":
      return "FMV";
    case "FMVIP":
      return "FMVIP";
    case "FMVR":
      return "FMVR";
    case "FAS":
      return "FAS";
    case "NFLV":
      return "NFLV";
    case "NOLV":
      return "NOLV";
    case "OLVIP":
      return "OLVIP";
    case "RCN":
      return "RCN";
    case "FV":
      return "FV";
    case "CV":
      return "CV";
    default:
      break;
  }
};

export const convertConditionToConditionValue = (condition) => {
  switch (condition) {
    case "New":
      return "N";
    case "Excellent":
      return "E";
    case "Very Good":
      return "VG";
    case "Good":
      return "G";
    case "Fair":
      return "F";
    case "Poor":
      return "P";
    case "NA":
      return "NA";
    case "Scrap":
      return "X";
    case "Salvage":
      return "S";
    default:
      return condition;
  }
};
export const convertConditionValueToCondition = (condition) => {
  switch (condition) {
    case "N":
      return "New";
    case "E":
      return "Excellent";
    case "VG":
      return "Very Good";
    case "G":
      return "Good";
    case "F":
      return "Fair";
    case "P":
      return "Poor";
    case "NA":
      return "NA";
    case "X":
      return "Scrap";
    case "S":
      return "Salvage";
    default:
      return condition;
  }
};
export const convertClassValueToClass = (key) => {
  switch (key) {
    case "P":
      return "Production Equipment";
    case "S":
      return "Production Support Equipment";
    case "M":
      return "Mobile Equipment";
    case "T":
      return "Transportation";
    case "G":
      return "General Plant Support Equipment";
    case "F":
      return "Office Furniture, Fixtures & Equipment";
    default:
      return "";
  }
};

export const reOrderArray = (arr, fromIndex, toIndex) => {
  let element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

export const myDebounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const createObjectFromArray = (dataArray) => {
  const result = {};
  dataArray.forEach((item) => {
    result[item.value_code] = ""; // Set the key as value_code and value as an empty string
  });
  return result;
};

export const calculateNavigation = (assetData, currentAssetId) => {
  if (isNaN(currentAssetId)) return {};
  const currentIndex = assetData.findIndex(
    (asset) => asset.type_id === currentAssetId,
  );

  const prev = currentIndex > 0 ? assetData[currentIndex - 1].type_id : null;

  const next =
    currentIndex < assetData.length - 1
      ? assetData[currentIndex + 1].type_id
      : null;

  return { prev, next };
};

export const generatePriceObject = (priceObject, values) => {
  let priceKeys = priceObject.split("#"),
    priceValues = values.split("#"),
    calculatedObject = {};
  console.log("🚀 ~ generatePriceObject ~ priceKeys:", priceKeys, priceValues);
  priceKeys.forEach((element, index) => {
    calculatedObject[element] = priceValues[index];
  });
  return calculatedObject;
};
export const generatePriceObjectWithNames = (priceObject, values) => {
  let priceKeys = priceObject.split("#"),
    priceValues = values.split("#"),
    calculatedObject = {};
  console.log("🚀 ~ generatePriceObject ~ priceKeys:", priceKeys, priceValues);
  priceKeys.forEach((element, index) => {
    const key = generateKey(element);
    calculatedObject[key] = priceValues[index];
  });
  return calculatedObject;
};

export const generatePriceObjectFromPriceVal = (priceObject) => {
  const computedPriceVal = {};
  Object.keys(priceObject).forEach((key) => {
    // generateKeyFromName(key);
    // computedPriceVal[key] = priceObject[key];
    computedPriceVal[generateKeyFromName(key)] = priceObject[key];
    // console.log(key, generateKeyFromName(key), priceObject[key], 'yoi');
  });
  console.log(
    "🚀 ~ generatePriceObjectFromPriceVal ~ computedPriceVal:",
    computedPriceVal,
  );
  return computedPriceVal;
};
export const storageCleanUp = () => {
  localStorage.removeItem("dropTo");
  localStorage.removeItem("insertPosition");
  localStorage.removeItem("editMode");
  // localStorage.removeItem('')
  // localStorage.removeItem('')
};

export const generateTypeAheadValues = (data) => {
  return "hello yoi";
};
export const getFormattedDateTime = () => {
  const now = new Date();

  const pad = (num) => num.toString().padStart(2, "0"); // we get a weird issue if the value is single digit so we just turn it into two here

  const year = now.getFullYear();
  const month = pad(now.getMonth() + 1);
  const day = pad(now.getDate());
  const hours = pad(now.getHours());
  const minutes = pad(now.getMinutes());

  // return `${year}-${month}-${day}T${hours}:${minutes}`
  // return `${year}-${month}-${day}`
  return { date: `${year}-${month}-${day}`, time: `T${hours}:${minutes}` };
};

export const convertTo24HourFormat = (input) => {
  return input; // no issues for now
};
export const capitalizeString = (inputString) => {
  return inputString
    .split(" ")
    .map((i) => `${i.charAt(0).toUpperCase()} ${i.slice(1)}`)
    .join(" ");
};

export const resetAssetFormData = (data) => {
  delete data.appraiser_note;
  delete data.asset_no;
  delete data.asset_type_id;
  delete data.capacity;
  delete data.cost;
  delete data.description;
  delete data.flagged;
  delete data.is_not_found;
  delete data.make;
  delete data.make_id;
  delete data.type_id;
  data.priceVal = {};
  delete data.serial_number;
  delete data.special_notes;
  delete data.type;
  delete data.year;
  delete data.model;
  delete data.mileage;
  delete data.area_asset_order;
  delete data.asset_id;
  delete data.model_label_id;
  delete data.serial_label;
  delete data.area_order;
  data.asset_class_id = 0;
  data.model_label_id = 1;
  data.condition = "Good";
  data.quantity_type = "number";
  data.serial_label_id = 1;
  data.mileage_type = "mileage";
  return data;
};

export const scrollToHighlightedRow = (typeId, rows) => {
  const currentRowIndex =
    rows.findIndex(({ type_id }) => +type_id === +typeId) + 1;
  const current_page_size = localStorage.getItem("itemsPerPage")
    ? localStorage.getItem("itemsPerPage")
    : 50;
  const divisionResult = currentRowIndex / +current_page_size;
  // 1 - multiple cases need to be handled thus the if and else if game
  // 2 - a little time till we move the pages thus the timeout to scroll
  let currentPageByRowIndex = 0;
  if (divisionResult % 1 >= 0.99) {
    currentPageByRowIndex = Math.ceil(divisionResult);
  } else if (divisionResult % 1 == 0) {
    currentPageByRowIndex = Math.ceil(divisionResult) - 1;
  } else {
    currentPageByRowIndex = Math.floor(divisionResult);
  }
  if (currentPageByRowIndex >= 0) {
    setTimeout(() => {
      const highlightedRow = document.getElementById("highlighted_row"); // ! I know it's pretty ugly and unconventional to use DOm manipulation in react but the case
      if (highlightedRow) {
        highlightedRow.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 1500);
    return currentPageByRowIndex;
  }
};

export const getFromLocalStorage = (key, fallback) => {
  const value = localStorage.getItem(key);
  return value && value !== "undefined" && value !== null ? value : fallback;
};
export const setInLocalStorage = (key, value = null) => {
  localStorage.setItem(key, value);
};

export const createUniqueUsernamesFromDuplicates = (data) => {
  return Array.from(
    new Map(data?.map((entry) => [entry.username, entry])).values(),
  );
};
export const createUniqueAreasFromDuplicates = (data) => {
  return Array.from(
    new Map(data?.map((entry) => [entry.area, entry])).values(),
  );
};

export const extractLineAndAsset = (inputObject) => {
  const lineList = [];
  const assetList = [];

  for (const key in inputObject) {
    if (inputObject.hasOwnProperty(key)) {
      const obj = inputObject[key];

      if (obj.hasOwnProperty("type")) {
        if (obj.type === "line") {
          lineList.push(obj);
        } else if (obj.type === "asset") {
          assetList.push(obj);
        }
      }
    }
  }

  return {
    lineList,
    assetList,
  };
};

export const updateLocationOrder = (assets) => {
  const newAssetData = [];
  // ! we now set a new field in the assets called locationOrder which we take from the locationData based on the indexof the location.
  //!  We then use this locationOrder field to order the assets rather than the location_id thus adding right ref to each asset/line
  assets.forEach((item, index) => {
    newAssetData[index] = {
      ...item,
      locationOrder: assetsState?.getAssetListData?.getLocationtData.findIndex(
        ({ location_id }) => +location_id === +item?.location_id,
      ),
    };
  });
  return newAssetData;
};

export const transformValues = (shortNames, values) => {
  const valueArray = values.split("#");
  return shortNames.split("#").reduce((acc, val, i) => {
    acc[val] = valueArray[i] || ""; // Default to empty string if no match
    return acc;
  }, {});
};

export const isDropAllowed = (objects, dropTo) => {
  let dropArray = objects?.dragFromArray;
  let flag;

  let minRange = dropArray[0].dragFrom;
  let maxRange = dropArray[dropArray.length - 1].dragFrom;
  // for (let i = 0; i < dropArray.length-1; i++) {
  if (+dropTo >= minRange && +dropTo <= maxRange) {
    flag = false;
  } else {
    flag = true;
  }
  // }
  return flag;
};

export const setLocalStorageItem = (key, value) => {
  if (value !== null && value !== undefined && value !== "") {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

export const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key);
};
