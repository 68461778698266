/* eslint-disable */
import {
  assetInEditPayload,
  condition,
  mileage,
  quantity,
  specialNote,
  specialNoteTypes,
} from "../../../constants/constant";
import * as routesNames from "../../../constants/routes";

import React, { useEffect, useRef, useState } from "react";
import {
  AlertMessage,
  ConfirmationAlert,
  CustomButton,
  CustomTextArea,
  CustomTextBox,
  Loader,
} from "../../../components";
import { decode as base64Decode } from "base-64";
import {
  addNewLine,
  deleteCurrentAsset,
  fetchAreaList,
  fetchLineByArea,
  fetchMakeCapacityList,
  getAssetDetailByLocation,
  getAssetInEdit,
  getAssetList,
  getLastAsset,
  postAsset,
} from "../../../services/apiService";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {
  Box,
  FormControl,
  Grid,
  Select,
  Typography,
  MenuItem,
  Tooltip,
  IconButton,
  CircularProgress,
  Menu,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import MyDiv from "./addAssets.style";
import styled from "styled-components";
// import typeahead from 'typeahead-standalone'; // imports library (js)

// import 'typeahead-standalone/dist/basic.css';
// import CustomTypeAhead from '../../../components/CustomTypeAhead';
import { Typeahead } from "react-bootstrap-typeahead";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Add,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getMakeCapacityAction } from "../../../redux/lines/LineActions";
import { getModelByMakeAction } from "../../../redux/assets/AssetsActions";
import {
  calculateNavigation,
  createObjectFromArray,
  generatePriceObject,
  generatePriceObjectFromPriceVal,
  generatePriceObjectWithNames,
  generateTypeAheadValues,
  storageCleanUp,
} from "../../../utils/Utils";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import InsertNewLineDialog from "../../../components/Tables/CustomTable/components/InsertNewLineDialog";
import {
  fetchPhotosByAssetId,
  postPhotosToAssets,
} from "../../../services/assetListService";
import FullSizeImagePopUp from "./fullSizeImage";
import AddAssetDialog from "../../AddAssetDialog";
import { postAssetTypeAdd } from "../../../services/adminService";

const MODEL_DATA = localStorage.getItem("make_model");

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    borderBottom: "1px solid #ccc",
    color: "#000000",
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Arial",
  },
  "&:first-child": { marginTop: -8, height: 30 },
  "&:last-child": { marginBottom: -8 },
}));

const AddAssets = () => {
  const projectIDCrm = localStorage.getItem("project_id_crm");
  const decodedProjectId = base64Decode(projectIDCrm);
  const projectId = +localStorage.getItem("project_id");
  let userId = localStorage.getItem("userId");
  const url = process.env.REACT_APP_IMAGE_BASE_URL;

  const history = useHistory();
  const params = useParams();

  const areaRef = useRef();
  const lineRef = useRef();
  const makeRef = useRef();
  const modelRef = useRef();
  const capacityRef = useRef();
  const assetTypeRef = useRef();
  // const assetNoRef = useRef()
  // const assetHashRef = useRef();

  const dispatch = useDispatch();

  const lineState = useSelector((state) => state.line);

  const makeList =
    lineState?.makeCapacityList?.makeCapacityList?.data?.makeList;
  console.log("🚀 ~ makeList:", makeList);

  const fullModelList = useSelector((state) => state.assets?.modelList);
  // console.log("🚀 ~ assetState:", assetState?.modelList)
  useEffect(() => {
    let makeCapacityData = {
      type: "getMakeCapacityList",
      project_id_crm: decodedProjectId,
    };

    dispatch(getMakeCapacityAction(makeCapacityData));
    if (!MODEL_DATA) dispatch(getModelByMakeAction());
  }, [dispatch]);
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [showLineAddModal, setShowLineAddModal] = useState(false);
  const [showAssetTypeModal, setShowAssetTypeModal] = useState(false);
  const [showFullImage, setShowFullImage] = useState(false);
  const [imagePath, setImagePath] = useState();
  const [addValue, setAddValue] = useState(null);

  const [assetPhotos, setAssetPhotos] = useState(null);
  const [assetPhotosCount, setAssetPhotosCount] = useState(0);
  // const [isMapNominateImageShow, setMapNominateImageShow] = useState(false)
  const [lineLoading, setLineLoading] = useState(false);
  const [lastAssetData, setLastAssetData] = useState(null);
  const [appraiserList, setAppraiserList] = useState(null);
  // const [showLineModal, setShowLineModal] = useState(false);
  const [previousAssetId, setPreviousAssetId] = useState(null);
  console.log("🚀 ~ previousAssetId:", previousAssetId);
  const [nextAssetId, setNextAssetId] = useState(null);
  const [lastFormAction, setLastFormAction] = useState(null);
  console.log("🚀 ~ nextAssetId:", nextAssetId);
  const [messageData, setMessageData] = useState(null);
  const [formFieldOptions, setFormFieldOptions] = useState({
    lines: [],
    areas: [],
    makes: [],
    modelLabelList: [],
    modelList: [],
    capacityList: [],
    assetTypeList: [],
    locationPriceValues: [],
  });
  console.log("formFieldOptions", formFieldOptions);
  console.log("🚀 ~ AddAssets ~ formFieldOptions:", formFieldOptions);
  const [formData, setFormData] = useState({});
  console.log("🚀 ~ AddAssets ~ formData:", formData);
  const [requestData, setRequestData] = useState({});
  console.log("🚀 ~ AddAssets ~ requestData:", requestData);

  const [lineFormData, setLineFormData] = useState({
    condition: "Good",
    asset_class_id: 1,
    line_text2: ", Consisting of:",
  });
  console.log("🚀 ~ lineFormData:", lineFormData);

  // ! was getting annoying and difficult to track loading in multiple methods leading to the loader being disabled even though
  // !all computations weren't complete. Thus the approach of startLoading and stop Loading using setLoadingCount state to set loading state to true or false
  const startLoading = () => setLoadingCount((prev) => prev + 1);
  const stopLoading = () => setLoadingCount((prev) => Math.max(prev - 1, 0));
  useEffect(() => {
    setLoading(loadingCount > 0);
  }, [loadingCount]);
  useEffect(() => {
    const fetchInitialData = async () => {
      startLoading();
      const getLastAssetPayload = {
        getLastAsset: true,
        project_id_crm: decodedProjectId,
      };
      const makeCapacityPayload = {
        type: "getMakeCapacityList",
        project_id_crm: decodedProjectId,
      };
      // const locationForRequest = localStorage.getItem('location_id_home') ? +localStorage.getItem('location_id_home') :
      // const lineByAreaPayload = { area: 'Undefined Area', location_id: 17640 };
      const assetByLocationPayload = { project_id_crm: decodedProjectId };

      try {
        const [lastAssetResponse, makeCapacityList, assetInEdit] =
          await Promise.all([
            getLastAsset(getLastAssetPayload),
            fetchMakeCapacityList(makeCapacityPayload),
            // fetchLineByArea(lineByAreaPayload),
            getAssetInEdit({
              projectId,
              type_id: params?.id ? params?.id : "",
              ...assetInEditPayload,
            }),
          ]);

        console.log("lastAssetResponse", lastAssetResponse);
        // setPreviousAssetId(lastAssetResponse.lastAsset?.type_id);

        console.log("makeCapacityList", makeCapacityList);

        const locationId = localStorage.getItem("location_id_home")
          ? +localStorage.getItem("location_id_home")
          : localStorage.getItem("location_id")
            ? localStorage.getItem("location_id")
            : lastAssetResponse?.lastAsset?.location_id;
        assetByLocationPayload.location_id = locationId;
        handleLineByArea("Undefined Area", locationId);
        const assetDetailResponse = await getAssetDetailByLocation(
          assetByLocationPayload,
        );
        setAppraiserList(assetDetailResponse.appraiserList);
        console.log(assetDetailResponse);
        const priceValObject = createObjectFromArray(
          assetDetailResponse.locationPriceValues,
        );
        //! We don't set the data if we already have an id in the params since we will be
        //! getting the actual data for the request in another block of code from the api.
        if (!params.id) {
          const initialData = {
            model_label_id: 1,
            location_id: locationId,
            appraiser: assetDetailResponse.appraiserList[0]?.user_id,
            condition: "Good",
            quantity_type: "number",
            quantity: "1",
            asset_class_id: 0,
            serial_label_id: 1,
            mileage_type: "mileage",
            project_id: lastAssetResponse.locationsDataFull[0]?.project_id,
            modified_by: Number(userId),
            created_by: Number(userId),
            priceVal: priceValObject,
            type: "asset",
            // ! this is for test purposes and stays for now
            // FLV: 10, cost:100, OLV: 20, FMV:50
          };
          const areaInStorage = localStorage.getItem("area");
          let computedFormData;
          if (areaInStorage) {
            initialData.area = areaInStorage;
            computedFormData =
              computeFormDataWithMultiSelectValues(initialData);
          }
          areaInStorage ? (initialData.area = areaInStorage) : null;

          // setFormFieldOptions({...formFieldOptions, modelLabelList: assetDetailResponse.modelLabelList})
          setFormData({ ...initialData, ...computedFormData });
          // const dropToId = +localStorage.getItem('dropTo')
          // if(dropToId && !isNaN(dropToId)) initialData.dropTo = dropToId
          setRequestData({ ...initialData });
        }

        const areaListResponse = await fetchAreaList(locationId);

        // let prev, next;
        let { prev, next } = calculateNavigation(
          assetInEdit?.asset_data,
          +params?.id,
        );
        setPreviousAssetId(prev ? prev : lastAssetResponse.lastAsset?.type_id);
        setNextAssetId(next);
        setFormFieldOptions((prevState) => ({
          ...prevState,
          projectName: lastAssetResponse.locationsDataFull[0]?.project_name,
          areas: areaListResponse,
          modelLabelList: assetDetailResponse.modelLabelList,
          capacityList: makeCapacityList.capacityList,
          assetTypeList: assetDetailResponse.assetTypeList,
          assetClassList: assetDetailResponse.assetClassList,
          locationPriceValues: assetDetailResponse.locationPriceValues,
          serialLabelList: assetDetailResponse.serialLabelList,
          currentRef: assetInEdit?.current_ref_no,
        }));

        setLastAssetData(lastAssetResponse);

        // assetHashRef.current.focus();
        stopLoading();
      } catch (error) {
        console.error("Error fetching initial data:", error);
        stopLoading();
      }
    };

    fetchInitialData();
  }, []);

  // useEffect(() => {
  // assetHashRef.current.focus();
  // }, []);

  const computeFormDataWithMultiSelectValues = (
    data,
    fallBackAssetTypeList,
  ) => {
    console.log("🚀 ~ computeFormDataWithMultiSelectValues ~ data:", data);
    const finalData = { ...data };
    Object.keys(data).forEach((key) => {
      if (key == "make" && data[key]) {
        finalData.selectedMake = [{ make: data.make, make_id: data.make_id }];
      } else {
        // makeRef.current.clear()
      }
      if (key == "model" && data[key]) {
        finalData.selectedModel = [{ model: data.model }];
      } else {
        // modelRef.current.clear()
      }
      if (key == "capacity" && data[key]) {
        finalData.selectedCapacity = [{ capacity: data.capacity }];
      } else {
        // capacityRef.current.clear();
      }
      if (key == "area" && data[key]) {
        finalData.selectedArea = [{ area: data.area }];
      } else {
        // areaRef.current.clear();
      }
      if (key === "parent_line") {
        finalData.selectedLine = data?.parent_line
          ? [
              {
                line_name: formFieldOptions?.lines.find(
                  ({ type_id }) => +type_id == +data.parent_line,
                )?.line_name,
                type_id: +data?.parent_line,
              },
            ]
          : [];
        // finalData.selectedLine = data.parent_line
        //   ? [
        //       {
        //         line_name: lineList?.filter(
        //           ({ type_id }) => +type_id == +data.parent_line
        //         )[0]?.line_name,
        //         type_id: +data?.parent_line,
        //       },
        //     ]
        //   : [];
      }
      if (key == "asset_type_id" && data[key]) {
        const list = formFieldOptions.assetTypeList.length
          ? formFieldOptions.assetTypeList
          : fallBackAssetTypeList;
        const foundAsset = list?.find(
          (item) => +item.asset_type_id === +data.asset_type_id,
        );
        finalData.selectedAssetType = [
          {
            asset_type_id: data.asset_type_id,
            asset_type: foundAsset?.asset_type,
          },
        ];
      }
    });
    // const multiSelectKeyArray = ['make_id', 'model', 'capacity', 'line_name', 'asset_type'];
    // data.forEach(item => {
    //   if(multiSelectKeyArray.includes(item)) {
    //     switch (item) {
    //       case value:

    //         break;

    //       default:
    //         break;
    //     }
    //   }
    // })
    return finalData;
  };
  useEffect(() => {
    // if(formFieldOptions.lines) {
    //     selectedLine = formData?.parent_line
    //       ? [
    //           {
    //             line_name: formFieldOptions?.lines.find(
    //               ({ type_id }) => +type_id == +formData.parent_line
    //             )?.line_name,
    //             type_id: +formData?.parent_line,
    //           },
    //         ]
    //       : [];

    // }
    [
      {
        line_name: formFieldOptions?.lines.find(
          ({ type_id }) => +type_id == +formData.parent_line,
        )?.line_name,
        type_id: +formData?.parent_line,
      },
    ];
    setFormData((prevState) => ({
      ...prevState,
      selectedLine: prevState?.parent_line
        ? [
            {
              line_name: formFieldOptions?.lines.find(
                ({ type_id }) => +type_id == +formData.parent_line,
              )?.line_name,
              type_id: +formData?.parent_line,
            },
          ]
        : null,
    }));
    setRequestData((prevState) => ({
      ...prevState,
      line_name: formFieldOptions?.lines.find(
        ({ type_id }) => +type_id == +formData.parent_line,
      )?.line_name,
    }));
  }, [formFieldOptions.lines]);
  useEffect(() => {
    if (!params.id) return;
    // todo - also hit the http://hilcoapi.mindstask.com/api/asset-list?action=getAssetInEdit api
    const getLastAssetData = async () => {
      startLoading();
      const payload = {
        project_id_crm: decodedProjectId,
        location_id: requestData.location_id,
        id: params.id,
      };

      const [assetPhotos, assetInEdit, lastAssetData] = await Promise.all([
        fetchPhotosByAssetId("asset", params.id),
        getAssetInEdit({
          projectId,
          type_id: params?.id ? params?.id : "",
          ...assetInEditPayload,
        }),
        getAssetDetailByLocation(payload),
      ]);
      console.log(
        assetPhotos,
        assetInEdit,
        lastAssetData?.assetTypeList,
        "assetPhotos",
      );
      if (
        assetPhotos?.asset_photos &&
        assetPhotos.asset_photos[params.id] !== undefined
      ) {
        setAssetPhotos(assetPhotos?.asset_photos[params.id]);
        setAssetPhotosCount(
          Object.keys(assetPhotos?.asset_photos[params.id]).length,
        );
      } else {
        setAssetPhotosCount(0);
      }
      // console.log(
      //   '🚀 ~ getLastAssetData ~ lastAssetData:',
      //   lastAssetData.assetDetails
      // );
      let { prev, next } = calculateNavigation(
        assetInEdit?.asset_data,
        +params?.id,
      );
      setPreviousAssetId(prev);
      setNextAssetId(next);
      const calculatedPriceObject = generatePriceObject(
        lastAssetData.assetDetails?.value_code,
        lastAssetData.assetDetails?.value,
      );
      const calculatedPriceObjectWithNames = generatePriceObjectWithNames(
        lastAssetData.assetDetails?.value_code,
        lastAssetData.assetDetails?.value,
      );
      const typeaheadValues = generateTypeAheadValues(
        lastAssetData.assetDetails,
      );
      console.log("🚀 ~ getLastAssetData ~ typeaheadValues:", typeaheadValues);
      console.log(
        "🚀 ~ getLastAssetData ~ calculatedPriceObject:",
        calculatedPriceObject,
        calculatedPriceObjectWithNames,
      );

      setFormFieldOptions((prevState) => ({
        ...prevState,
        currentRef: assetInEdit?.current_ref_no,
      }));
      // setRequestData({ priceVal: calculatedPriceObject, ...lastAssetData.assetDetails });
      // const dropToId = +localStorage.getItem('dropTo');
      // if (dropToId && !isNaN(dropToId)) lastAssetData.assetDetails.dropTo = dropToId;
      setRequestData({
        ...lastAssetData.assetDetails,
        type: "asset",
        priceVal: calculatedPriceObjectWithNames,
      });
      const computedFormData = computeFormDataWithMultiSelectValues(
        lastAssetData.assetDetails,
        lastAssetData?.assetTypeList,
      );
      // console.log(
      //   '🚀 ~ getLastAssetData ~ computedFormData:',
      //   computedFormData
      // );
      setFormData({
        ...computedFormData,
        ...calculatedPriceObject,
        type: "asset",
      });
      // setFormData(lastAssetData.assetDetails);
      console.log("params", params);
      stopLoading();
    };
    getLastAssetData();
  }, [params.id]);

  useEffect(() => {
    if (makeList?.length && formData?.make_id) {
      const modelsByMake = fullModelList?.filter(
        ({ make_id }) => make_id === formData.make_id,
      );
      return setFormFieldOptions((prevState) => ({
        ...prevState,
        modelList: modelsByMake,
      }));
    }
    setFormFieldOptions((prevState) => ({
      ...prevState,
      modelList: [],
    }));
  }, [formData?.make_id, formFieldOptions.makes, makeList]);

  useEffect(() => {
    // ! maybe, just maybe remove this later?
    const handler = setTimeout(() => {
      if (formData.area) {
        handleLineByArea(formData.area, formData.location_id);
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [formData.location_id]);

  useEffect(() => {
    return;
    const initializeTypeahead = () => {
      if (areaRef.current && areaList) {
        typeahead({
          input: areaRef.current,
          highlight: true,
          source: {
            local: areaList.map((area) => area.area),
          },
        });
      }
    };

    // Retry initialization with a timeout if areaRef is not ready
    if (areaRef.current && areaList) {
      initializeTypeahead();
    } else {
      const timeoutId = setTimeout(initializeTypeahead, 100);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  // const handleLineByArea = (area, location_id) => {
  //   if (area && location_id) {
  //     setTimeout(async () => {
  //     setLineLoading(true);
  //     const linesByArea = await fetchLineByArea({ area, location_id });
  //     setFormFieldOptions((prevState) => ({
  //       ...prevState,
  //       lines: linesByArea,
  //     }));
  //     setLineLoading(false);
  //     }, 1500);
  //     // console.log('🚀 ~ handleLineByArea ~ linesByArea:', linesByArea);
  //   }
  // };
  const debounceTimeout = useRef(null);

  const handleLineByArea = (area, location_id) => {
    if (area && location_id) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(async () => {
        setLineLoading(true);
        const linesByArea = await fetchLineByArea({ area, location_id });
        setFormFieldOptions((prevState) => ({
          ...prevState,
          lines: linesByArea,
        }));
        setLineLoading(false);
      }, 500);
    }
  };

  const handleCustomTypeAheadForArea = (areaVal) => {
    if (Array.isArray(areaVal)) return;
    setFormData((prevState) => ({
      ...prevState,
      selectedLine: [],
      selectedArea: [{ area: areaVal }],
      area: areaVal,
    }));
    setRequestData((prevState) => ({
      ...prevState,
      area: areaVal,
    }));
    handleLineByArea(
      areaVal ? areaVal : "Undefined Area",
      requestData.location_id,
    ); // ! although we never have areaVal here
    // fetchLineByArea(areaVal, locationId).then((res) => {
    //   // console.log(res)
    // })
    // lineRef.current.clear()
  };
  const handleCustomTypeAheadForModel = (modelValue) => {
    if (Array.isArray(modelValue)) return;
    setFormData((prevState) => ({
      ...prevState,
      selectedModel: [{ model: modelValue }],
      model: modelValue,
    }));
    setRequestData((prevState) => ({
      ...prevState,
      model: modelValue,
    }));
  };
  const handleCustomTypeAheadForCapacity = (capacityValue) => {
    if (Array.isArray(capacityValue)) return;
    setFormData((prevState) => ({
      ...prevState,
      selectedCapacity: [{ capacity: capacityValue }],
      capacity: capacityValue,
    }));
    setRequestData((prevState) => ({
      ...prevState,
      capacity: capacityValue,
    }));
  };
  const handleCustomTypeAheadForMake = (makeValue) => {
    if (Array.isArray(makeValue)) return;
    setFormData((prevState) => ({
      ...prevState,
      selectedMake: [{ make: makeValue }],
      make: makeValue,
      make_id: null,
    }));
    setRequestData((prevState) => ({
      ...prevState,
      make: makeValue,
      make_id: null,
    }));
  };
  const handleKeyDownForTypeAhead = (e) => {
    const { placeholder, value } = e.target;

    if (!value) return;
    if (
      placeholder === "Search Area" &&
      value.length >= 250 &&
      e.key.length === 1 &&
      !e.ctrlKey &&
      !e.metaKey
    ) {
      e.preventDefault();
      return;
    }
    if (
      placeholder === "Search Line" &&
      value.length >= 500 &&
      e.key.length === 1 &&
      !e.ctrlKey &&
      !e.metaKey
    ) {
      e.preventDefault();
      return;
    }
    // const makeId = makeList?.find(({ make }) => make === value)?.make_id;
    // const lineId = lineList.find(({ line_name }) => line_name === value)?.type_id;
    // const assetTypeValue = filteredAssetTypes?.find(({ asset_type }) => asset_type === value);
    let makeId, lineId, assetTypeValue;

    const updateFormAndRequestData = (formDataUpdates, requestDataUpdates) => {
      setFormData((prevData) => ({ ...prevData, ...formDataUpdates }));
      setRequestData((prevData) => ({ ...prevData, ...requestDataUpdates }));
    };
    const handleUpdate = () => {
      switch (placeholder) {
        case "Search Area":
          if (value.lenth > 250) return;
          updateFormAndRequestData(
            { selectedLine: [], selectedArea: [{ area: value }], area: value },
            { area: value },
          );
          handleLineByArea(value, requestData.location_id);

          // lineRef.current.clear();
          break;
        case "Search Make":
          updateFormAndRequestData(
            { selectedMake: [{ make: value }], make: value, make_id: makeId },
            { make: value, make_id: makeId },
          );
          break;
        case "Search Model":
          updateFormAndRequestData(
            { selectedModel: [{ model: value }], model: value },
            { model: value },
          );
          break;
        case "Search Capacity":
          updateFormAndRequestData(
            { selectedCapacity: [{ capacity: value }], capacity: value },
            { capacity: value },
          );
          break;
        case "Search Line":
          lineId = formFieldOptions.lines.find(
            ({ line_name }) => line_name === value,
          )?.type_id;
          updateFormAndRequestData(
            {
              selectedLine: [{ line_name: value, type_id: lineId }],
              line_name: value,
            },
            { line_name: value, parent_line: lineId },
          );
          break;
        case "Search Asset Types":
          assetTypeValue = formFieldOptions.assetTypeList?.find(
            ({ asset_type }) => asset_type === value,
          );
          updateFormAndRequestData(
            {
              selectedAssetType: [{ asset_type: value }],
              asset_type: value,
              asset_type_id: assetTypeValue?.asset_type_id,
              asset_class_id: assetTypeValue?.class_id,
            },
            {
              asset_type: value,
              asset_type_id: assetTypeValue?.asset_type_id,
              asset_class_id: assetTypeValue?.class_id,
            },
          );
          break;
        default:
          break;
      }
    };

    const handleClear = () => {
      switch (placeholder) {
        case "Search Area":
          updateFormAndRequestData(
            { selectedLine: [], selectedArea: [], area: null },
            { area: null },
          );
          handleLineByArea("Undefined Area", requestData.location_id);
          // lineRef.current.clear();
          break;
        case "Search Make":
          updateFormAndRequestData(
            { selectedMake: [], make: null, make_id: null },
            { make: null, make_id: null },
          );
          break;
        case "Search Model":
          updateFormAndRequestData(
            { selectedModel: [], model: null },
            { model: null },
          );
          break;
        case "Search Capacity":
          updateFormAndRequestData(
            { selectedCapacity: [], capacity: null },
            { capacity: null },
          );
          break;
        case "Search Line":
          updateFormAndRequestData(
            { selectedLine: [], line_name: null },
            { line_name: null, parent_id: null },
          );
          break;
        case "Search Asset Types":
          updateFormAndRequestData(
            {
              selectedAssetType: [],
              asset_type: null,
              asset_type_id: null,
              asset_class_id: null,
            },
            { asset_type: null, asset_type_id: null, asset_class_id: null },
          );
          break;
        default:
          break;
      }
    };

    if (e.keyCode === 9) {
      handleUpdate();
    } else if (e.keyCode === 8 && value === "") {
      handleClear();
    }
  };
  const saveBeforeMove = (type) => {
    if (type == "list") {
      handleSubmit("list");
    } else if (type == "next") {
      handleSubmit("next");
    } else {
      handleSubmit("prev");
    }
  };
  const deleteAsset = () => {
    alert("will implement later");
  };
  const resetForm = () => {
    window.location.reload();
    // alert('will implement later');
  };

  const handleSelected = (selected, type) => {
    // setChange(true);

    if (selected.length === 0) return;

    const label = selected[0]?.label ? true : false;
    const item = selected[0];

    // Helper to update formData and requestData simultaneously
    const updateFormAndRequestData = (formUpdates, requestUpdates) => {
      setFormData((prevData) => ({ ...prevData, ...formUpdates }));
      setRequestData((prevData) => ({ ...prevData, ...requestUpdates }));
    };

    switch (type) {
      case "area":
        updateFormAndRequestData(
          {
            selectedLine: [],
            selectedArea: selected,
            area: label ? item.label : item.area,
          },
          { area: label ? item.label : item.area },
        );
        handleLineByArea(item.area, requestData.location_id);
        // lineRef.current.clear();
        break;
      case "line":
        updateFormAndRequestData(
          {
            selectedLine: selected,
            line_name: label ? item.label : item.line_name,
          },
          { line_name: label ? item.label : item.line_name },
        );
        break;
      case "make":
        updateFormAndRequestData(
          {
            selectedMake: selected,
            make: label ? item.label : item.make,
            make_id: label ? null : item.make_id,
          },
          {
            make: label ? item.label : item.make,
            make_id: label ? null : item.make_id,
          },
        );
        break;
      case "model":
        updateFormAndRequestData(
          { selectedModel: selected, model: label ? item.label : item.model },
          { model: label ? item.label : item.model },
        );
        break;
      case "capacity":
        updateFormAndRequestData(
          {
            selectedCapacity: selected,
            capacity: label ? item.label : item.capacity,
          },
          { capacity: label ? item.label : item.capacity },
        );
        break;
      case "assetType":
        updateFormAndRequestData(
          {
            selectedAssetType: selected,
            asset_type: label ? item.label : item.asset_type,
            asset_type_id: label ? null : item.asset_type_id,
            asset_class_id: item.class_id ? +item.class_id : 0,
          },
          {
            asset_type: label ? item.label : item.asset_type,
            asset_type_id: label ? null : item.asset_type_id,
            asset_class_id: item.class_id ? +item.class_id : 0,
          },
        );
        break;
      default:
        break;
    }
  };
  const handleSelectedForLineAndAssetType = (selected, type) => {
    // setChange(true);
    let label;
    if (selected[0]?.label) label = true;
    if (type === "assetType") {
      const commonData = {
        asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
        asset_type_id: label
          ? null
          : selected[0]?.asset_type_id
            ? selected[0]?.asset_type_id
            : null,
        asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
      };
      setFormData((prevState) => ({
        ...prevState,
        selectedAssetType: selected,
        ...commonData,
      }));
      setRequestData((prevState) => ({
        ...prevState,
        ...commonData,
      }));
    } else if (type === "line") {
      setFormData((prevState) => ({
        ...prevState,
        selectedLine: selected,
        line_name: label ? selected[0]?.label : selected[0]?.line_name,
      }));
      setRequestData((prevState) => ({
        ...prevState,
        line_name: label ? selected[0]?.label : selected[0]?.line_name,
        parent_line: selected[0]?.type_id,
      }));
    }
  };
  // const handleSelectedForLineAndAssetType = (selected, type) => {
  //   // setChange(true);

  //   if (!selected.length) return;

  //   const label = selected[0]?.label ? true : false;
  //   const item = selected[0];

  //   const formDataUpdates = {};
  //   const requestDataUpdates = {};

  //   if (type === 'assetType') {
  //     formDataUpdates.selectedAssetType = selected;
  //     formDataUpdates.asset_type = label ? item.label : item.asset_type;
  //     formDataUpdates.asset_type_id = label ? null : item.asset_type_id;
  //     formDataUpdates.asset_class_id = item.class_id ? +item.class_id : 0;

  //     requestDataUpdates.asset_type = label ? item.label : item.asset_type;
  //     requestDataUpdates.asset_type_id = label ? null : item.asset_type_id;
  //     requestDataUpdates.asset_class_id = item.class_id ? +item.class_id : 0;
  //   } else if (type === 'line') {
  //     formDataUpdates.selectedLine = selected;
  //     formDataUpdates.line_name = label ? item.label : item.line_name;
  //     requestDataUpdates.line_name = label ? item.label : item.line_name;
  //   }

  //   setFormData((prevData) => ({ ...prevData, ...formDataUpdates }));
  //   setRequestData((prevData) => ({ ...prevData, ...requestDataUpdates }));
  // };

  const handleAssetFocus = () => {
    const allowedValues = ["no value", "leased", "not valued"];
    const validatedPriceObject = {};
    for (const [key, value] of Object.entries(requestData.priceVal)) {
      // Normalize value for validation
      const normalizedValue = value?.toLowerCase()?.trim();
      validatedPriceObject[key] =
        allowedValues.includes(normalizedValue) || !isNaN(+value) ? value : "";
    }
    console.log(
      "🚀 ~ handleAssetFocus ~ validatedPriceObject:",
      validatedPriceObject,
    );
  };

  useEffect(() => {
    if (
      requestData?.special_notes &&
      !specialNote?.includes(requestData?.special_notes)
    ) {
      setFormData((prevState) => ({ ...prevState, isCustomNoteMode: true }));
    }
  }, [requestData]);
  const handleChange = (e, type) => {
    const allowedValues = ["no value", "leased", "not valued"];
    const { name, value, checked } = e.target;
    console.log(name, value, e, "sss");
    if (name == "special_notes" && value == "Create a New Note") {
      setFormData((prevState) => ({ ...prevState, isCustomNoteMode: true }));
    } else if (
      name == "special_notes" &&
      specialNote.includes(value) &&
      value !== "Create a New Note"
    ) {
      setFormData((prevState) => ({ ...prevState, isCustomNoteMode: false }));
    }
    if (name == "quantity" && value.length <= 6) {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
      setRequestData((prevState) => ({ ...prevState, [name]: value }));
      return;
    } else if (name === "quantity") {
      return;
    }
    if (type === "found") {
      setFormData((prevState) => ({ ...prevState, is_not_found: value }));
      setRequestData((prevState) => ({ ...prevState, is_not_found: value }));
      return;
    }
    if (type === "location") {
      if (formData.type_id) {
        setFormData((prevState) => ({ ...prevState, location_id: value }));
        setRequestData((prevState) => ({ ...prevState, location_id: value }));
        return;
      } else {
        localStorage.setItem("location_id", value);
        window.location.reload();
      }
    }
    if (type === "flagged") {
      setFormData((prevData) => ({ ...prevData, [name]: checked ? 1 : 0 }));
      setRequestData((prevData) => ({ ...prevData, [name]: checked ? 1 : 0 }));
      return;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (type === "priceValue") {
      if (!allowedValues.includes(value.toLowerCase()) && isNaN(value)) return;
      setRequestData((prevData) => {
        const priceFieldValue = { ...requestData.priceVal };
        const valueCode = formFieldOptions.locationPriceValues.find(
          ({ value_short_name }) => value_short_name === name,
        )?.value_code;
        if (valueCode) {
          priceFieldValue[valueCode] = value;
        }
        return { ...prevData, priceVal: priceFieldValue };
      });
      return;
    }
    setRequestData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateCost = (item) => {
    // ! A total of 6 cases are being handled as per the requirements
    switch (item) {
      case "FLV":
        if (+formData.FLV > +formData.OLV || +formData.FLV > +formData.FMV)
          return true;
        break;
      case "OLV":
        if (+formData.OLV > +formData.FMV || +formData.OLV > +formData.OLVIP)
          return true;
        break;
      case "FMV":
        if (+formData.FMV > +formData.FMVIP) return true;
        break;
      case "OLVIP":
        if (+formData.OLVIP > +formData.FMVIP) return true;
        break;
      default:
        break;
    }
  };
  //   const handleAssetFocus = () => {
  //   let updatedInputValues = { ...formData }
  //   const allowedValues = ['no value', 'leased', 'not valued'];
  //   formFieldOptions.locationPriceValues.forEach((item) => {
  //     if (allowedValues.includes(updatedInputValues[item.value_short_name]?.toLowerCase()?.trim()) || +updatedInputValues[value_short_name] > 0) {
  //       // Valid value, do nothing
  //     } else {
  //       updatedInputValues[val] = '' // Reset invalid value
  //     }
  //   })

  //   setFormData(updatedInputValues)
  //   // setRequestData({...requestData, })// ! we fucked upppp
  // };
  const getPriceValues = (type) => {
    console.log("hello");
    console.log(formFieldOptions.locationPriceValues);
    // if(type == 'line') {}
    return formFieldOptions.locationPriceValues.map((item, index) => {
      if (type == "line") {
        let percentageValue =
          (lineFormData[item.value_short_name] / lineFormData?.cost) * 100;
        return (
          <Grid item md={2} key={index}>
            <Box className="input_box">
              <Typography>{item.value_short_name} ($):</Typography>
              {!isNaN(parseInt([item.value_short_name])) &&
              lineFormData?.cost > 0 ? (
                <Typography>{percentageValue.toFixed(2)}% of Cost</Typography>
              ) : (
                ""
              )}
              <CustomTextBox
                className={
                  validateCost(item.value_short_name)
                    ? "coral_background"
                    : null
                }
                // className={'coral_background'}
                onChange={(e) => handleLineFormChange(e, "priceValue")}
                name={item.value_short_name}
                value={lineFormData[item.value_short_name]}
                onBlur={handleAssetFocus}
              />
            </Box>
          </Grid>
        );
      }
      let percentageValue =
        (formData[item.value_short_name] / formData?.cost) * 100;
      return (
        <Grid item md={2} key={index}>
          <Box className="input_box">
            <Typography>{item.value_short_name} ($):</Typography>
            {!isNaN(parseInt(formData[item.value_short_name])) &&
            formData?.cost > 0 ? (
              <Typography>{percentageValue.toFixed(2)}% of Cost</Typography>
            ) : (
              ""
            )}
            <CustomTextBox
              className={
                validateCost(item.value_short_name) ? "coral_background" : null
              }
              // className={'coral_background'}
              onChange={(e) => handleChange(e, "priceValue")}
              name={item.value_short_name}
              value={formData[item.value_short_name]}
              onBlur={handleAssetFocus}
            />
          </Box>
        </Grid>
      );
    });
  };

  const handleMapPhotos = () => {
    alert("will handle it");
    history.push(`/map-photos/${formData.appraiser}`);
    // let url = window.location.href;
    // let response = assetState?.postAddAsset?.postAddAsset?.data;

    // if (
    //   Object.keys(inputValues).length !== 0 &&
    //   (inputValues?.user_id !== null || inputValues?.appraiser !== null)
    // ) {
    //   history.push({
    //     pathname: `/map-photos/${inputValues?.appraiser}`,
    //     state: { data: { data, inputValues, response, url } },
    //   });
    // } else {
    //   setMessage('Choose Appraiser First.');
    //   setStatus('warning');
    // }
  };

  const checkRequestDataValidity = () => {
    const allowedValues = ["no value", "leased", "not valued"];
    const validationData = { ...requestData };
    const defaultKeys = [
      "location_id",
      "project_id",
      // "quantity",
      "condition",
      "mileage_type",
      "quantity_type",
      "model_label_id",
      "serial_label_id",
      "appraiser",
      "created_by",
      "modified_by",
      "type",
    ];
    // ! only in case of save and create new
    const isInsertMode = localStorage.getItem("insertPosition");
    if (
      (lastFormAction && lastFormAction === "saveCreateNew") ||
      isInsertMode
    ) {
      delete validationData.area;
      delete validationData.line_name;
      delete validationData.parent_line;
    }
    if (validationData.quantity && +validationData.quantity <= 1) {
      delete validationData.quantity;
    }
    defaultKeys.forEach((key) => delete validationData[key]);
    const priceIsValid = Object.values(validationData.priceVal || {}).some(
      (value) => allowedValues.includes(value) || !isNaN(parseInt(value)),
    );
    // ! Just to make it clear we always have asset_class_id and priceVal in our
    //! requestData so we check for their validity and also check if we have values in any other fields other than these two
    return (
      +validationData.asset_class_id > 0 ||
      priceIsValid ||
      Object.keys(validationData).length > 2
    );
  };
  const handleDelete = () => {
    console.log(previousAssetId, "we go here?");
    // computing for deleting current row and active previous row highlight
    // const currentRowIndex = data?.rows?.findIndex(
    //   (item) => item?.type_id === Number(params?.id)
    // );
    // const currentRowOrder = currentRowIndex - 1;
    // const previousRowData = data?.rows?.find(
    //   (item) => data?.rows?.indexOf(item) === currentRowOrder
    // );
    // dispatch(deleteInnerAssetAction(typeId ? typeId : params.id));
    deleteCurrentAsset(formData.type_id);
    localStorage.setItem("type_id", previousAssetId);
    history.push(routesNames.ASSETLIST);
  };
  const handleSubmit = async (submitType) => {
    // todo something to do with parent_line
    // todo something to do with fuckig count
    // ! loader
    // if (!requestData.created_by && !params.id) {
    if (submitType === "delete") {
      setShowDeleteConfirmation(true);
    }
    // if (!params.id || !formData.type_id || !checkRequestDataValidity()) {
    if (!formData.type_id && !checkRequestDataValidity()) {
      // ! original version checks for appraiser but since we know we are in edit mode if we have created_by we'll just use it?
      if (submitType == "list") {
        localStorage.removeItem("dropTo");
        localStorage.removeItem("area");
        localStorage.setItem("alreadyScrolled", 0);
        // formData?.type_id
        //   ? localStorage.setItem("type_id", formData.type_id)
        //   : null;
        history.push(routesNames.ASSETLIST);
        // $location.path('/assetList');
        return false;
      } else if (submitType == "next") {
        localStorage.removeItem("dropTo");
        localStorage.removeItem("area");
        history.push(`/asset-detail/${nextAssetId}`);
        // $location.path(
        //   '/assetdetail/' + $scope.nextAssetId
        // );
        return false;
      } else if (submitType == "prev") {
        localStorage.removeItem("dropTo");
        localStorage.removeItem("area");
        history.push(`/asset-detail/${previousAssetId}`);
        // $location.path(
        //   '/assetdetail/' + $scope.previousAssetId
        // );
        return false;
      }
    }
    startLoading();
    if (!checkRequestDataValidity()) {
      stopLoading();
      return setMessageData({
        message: "Please enter something",
        status: "warning",
      });
    }
    let responseRequestData = { ...requestData };
    const insertPosition = localStorage.getItem("insertPosition");
    // if (
    //   // ! the actual check
    //   // addAssetData &&
    //   // (addAssetData.insertPosition == 'above' ||
    //   //   addAssetData.insertPosition == 'below') &&
    //   // (submitType != 'saveCopyNew' || submitType != 'saveCopyBelow')
    //   // ! my custom check which needs to be updated later for obvious reasons
    //   !responseRequestData.type_id &&
    //   responseRequestData.dropTo &&
    //   (insertPosition == 'above' || insertPosition == 'below') &&
    //   (submitType != 'saveCopyNew' || submitType != 'saveCopyBelow')
    // ) {
    //   // ! store in localStorage and fetch here
    //   responseRequestData.dropTo += 1;
    // } else {
    //   delete responseRequestData.dropTo;
    // }
    const dropToId = +localStorage.getItem("dropTo");
    if (!responseRequestData.type_id && dropToId) {
      responseRequestData.dropTo =
        insertPosition == "below" ? dropToId + 1 : dropToId;
    } else if (responseRequestData.type_id) {
      delete responseRequestData.dropTo;
    }

    // const updateRequestData = {}
    // const response = await postAsset(requestData);
    const response = await postAsset(responseRequestData); // ! submit request api

    setLastFormAction(submitType); // track last action

    localStorage.removeItem("typeIdForEdit");
    localStorage.removeItem("editMode");
    localStorage.removeItem("area");
    // todo 1 we remove the addasset in storage (always just do this???)
    console.log("final update", responseRequestData);

    if (requestData?.type_id) {
      // ! if we do not have type_id in  the formdata and we are in addAsset mode then we add dropTo
      //Add Asset above/below
      // if ($scope.addAssetCookie != undefined && !$scope.formData.type_id) {
      //   $scope.formData.dropTo = $scope.addAssetCookie.dropTo;
      //   $scope.formData.area = $scope.addAssetCookie.areaTo;
      //   $scope.formData.location_id = $scope.addAssetCookie.locationTo;
      //   if ($scope.addAssetCookie.lineTo != undefined) {
      //     $scope.formData.parent_line = $scope.addAssetCookie.lineTo;
      //   }
      // } else if (
      //   $scope.addAssetCookie != undefined &&
      //   $scope.formData.type_id
      // ) {
      //   delete $scope.formData.dropTo;
      // }
      // else if ($scope.addAssetCookie != undefined && $scope.formData.type_id)
      // delete $scope.formData.dropTo;
      // $cookieStore.put("type_id", parseInt(requestData.type_id));
      localStorage.setItem("type_id", parseInt(requestData.type_id)); // !Stores the ID of the last accessed or viewed asset.
    } else if (parseInt(response.id) > 0) {
      responseRequestData.type_id = response.id;
      // $cookieStore.put("type_id", parseInt(res.id));
      localStorage.setItem("type_id", parseInt(response.id)); // !Stores the ID of the last accessed or viewed asset.
      // localStorage.setItem('editTypeID', parseInt(response.id)) //! Stores the ID of the asset currently being edited.
      // $cookieStore.put("editTypeID", parseInt(res.id));
      // if ($cookieStore.get("assetListFilter") && parseInt(res.asset_id))
      // {
      //     $cookieStore.put('assetIdToGoAfterDelete', parseInt(res.asset_id));
      // }
      // $scope.formData.type_id = parseInt(res.id);
    }
    // setRequestData({...requestData, type_id: parseInt(response.id)})
    // setFormData({...requestData, type_id: parseInt(response.id)})
    // todo 2 if requestData had type_id we update type_id in storage
    if (submitType != "saveCopyBelow" && requestData.copy_type_id) {
      delete responseRequestData.copy_type_id;
      // todo  handle case
      // ? delete copy_type_id from data (both request and form data)
    }
    if (submitType == "saveCreateNew") {
      // todo handle case
      makeRef.current?.clear();
      capacityRef.current?.clear();
      if (
        dropToId &&
        (insertPosition == "above" || insertPosition == "below") &&
        (submitType != "saveCopyNew" || submitType != "saveCopyBelow")
      ) {
        localStorage.setItem("dropTo", dropToId + 1);
        // $scope.addAssetCookie.dropTo = parseInt($scope.addAssetCookie.dropTo) + 1;
      }
      // const localObj = {
      //   project_id: responseRequestData.project_id,
      //   location_id: responseRequestData.location_id,
      //   appraiser: responseRequestData.appraiser,
      // };

      // responseRequestData.area
      //   ? (localObj.area = responseRequestData.area)
      //   : '';

      // responseRequestData.parent_line
      //   ? ((localObj.parent_line = responseRequestData.parent_line),
      //     (localObj.line_name = responseRequestData.line_name))
      //   : '';

      // localObj.mileage_type = 'mileage';
      // localObj.condition = 'Good';
      // localObj.quantity = 1;
      // localObj.quantity_type = 'number';
      // localObj.serial_label_id = responseRequestData.serial_label_id; // !needs validation
      // localObj.model_label_id = responseRequestData.model_label_id;
      //     // asset_class_id: 0,
      //     localObj.modified_by = Number(userId)
      //     localObj.created_by = Number(userId)
      delete responseRequestData?.appraiser_note;
      delete responseRequestData?.asset_no;
      delete responseRequestData?.asset_type_id;
      delete responseRequestData?.capacity;
      delete responseRequestData?.cost;
      delete responseRequestData?.description;
      delete responseRequestData?.flagged;
      delete responseRequestData?.is_not_found;
      delete responseRequestData?.make;
      delete responseRequestData?.make_id;
      delete responseRequestData?.type_id;
      responseRequestData.priceVal = {};
      delete responseRequestData?.serial_number;
      delete responseRequestData?.model;
      delete responseRequestData?.asset_class_id;
      delete responseRequestData?.mileage;
      responseRequestData.quantity = 1;
      responseRequestData.quantity_type = "number";
      responseRequestData.model_label_id = 1;
      responseRequestData.mileage_type = "mileage";
      responseRequestData.serial_label_id = 1;
      responseRequestData.condition = "Good";
      delete responseRequestData?.special_notes;
      // delete responseRequestData?.type;
      delete responseRequestData?.year;
      // delete responseRequestData?.
      // delete responseRequestData?.

      // if (
      //   // ! the actual check
      //   // addAssetData &&
      //   // (addAssetData.insertPosition == 'above' ||
      //   //   addAssetData.insertPosition == 'below') &&
      //   // (submitType != 'saveCopyNew' || submitType != 'saveCopyBelow')
      //   // ! my custom check which needs to be updated later for obvious reasons
      //   responseRequestData.dropTo &&
      //   (responseRequestData.insertPosition == 'above' ||
      //     responseRequestData.insertPosition == 'below') &&
      //   (submitType != 'saveCopyNew' || submitType != 'saveCopyBelow')
      // ) {
      //   // ! store in localStorage and fetch here
      //   responseRequestData.dropTo += 1;
      // } else {
      //   delete responseRequestData.dropTo
      // }

      setPreviousAssetId(response.id);
      // responseRequestData = localObj
    } else if (submitType == "saveCopyNew" || submitType == "saveCopyBelow") {
      // todo handle case
      submitType === "saveCopyBelow"
        ? (responseRequestData.copy_type_id = responseRequestData.type_id)
        : responseRequestData.copy_type_id &&
          delete responseRequestData.copy_type_id;

      delete responseRequestData.type_id;
      setPreviousAssetId(response.id);
    } else if (submitType == "list") {
      localStorage.removeItem("alreadyScrolled");
      localStorage.removeItem("area");
      // localStorage.setItem('type_id', params.id); // ! why?
      history.push(routesNames.ASSETLIST);
    } else if (submitType == "next") {
      // todo handle case
      history.push(`/asset-detail/${nextAssetId}`);
    } else if (submitType == "prev") {
      // todo handle case
      history.push(`/asset-detail/${previousAssetId}`);
    } else {
      // todo fallback and default case
    }
    setRequestData(responseRequestData);
    const calculatedPriceObject = generatePriceObjectFromPriceVal(
      responseRequestData?.priceVal,
    );
    const computedFormData =
      computeFormDataWithMultiSelectValues(responseRequestData);
    setFormData({
      ...responseRequestData,
      ...calculatedPriceObject,
      ...computedFormData,
    });
    //     if ($scope.addAssetCookie != undefined && !$scope.formData.type_id) {
    //     $scope.formData.dropTo = $scope.addAssetCookie.dropTo;
    //     $scope.formData.area = $scope.addAssetCookie.areaTo;
    //     $scope.formData.location_id = $scope.addAssetCookie.locationTo;
    // }
    // Retrieve data from localStorage
    // const addAssetCookie = JSON.parse(localStorage.getItem('addAssetCookie'));
    // const formData = JSON.parse(localStorage.getItem('formData')) || {};

    // Check if addAssetCookie exists and formData.type_id is not set
    // if (addAssetCookie && !formData.type_id) {
    //   formData.dropTo = addAssetCookie.dropTo;
    //   formData.area = addAssetCookie.areaTo;
    //   formData.location_id = addAssetCookie.locationTo;

    //   // Save the updated formData back to localStorage
    //   localStorage.setItem('formData', JSON.stringify(formData));
    // }

    // setTimeout(() => {
    stopLoading();
    // }, 2500);
  };

  const handleLineFormChange = (e, type) => {
    const { name, value } = e.target;
    const allowedValues = ["no value", "leased", "not valued"];
    const maxLengths = {
      line_text2: 100,
      line_name: 500,
    };
    if (type === "priceValue") {
      if (!allowedValues.includes(value.toLowerCase()) && isNaN(value)) return;
      setLineFormData((prevData) => {
        const priceFieldValue = { ...lineFormData.priceVal };
        const valueCode = formFieldOptions.locationPriceValues.find(
          ({ value_short_name }) => value_short_name === name,
        )?.value_code;
        if (valueCode) {
          priceFieldValue[valueCode] = value;
        }
        return { ...prevData, priceVal: priceFieldValue };
      });
      return;
    }
    if (maxLengths[name] && value.length >= maxLengths[name]) {
      return;
    }
    setLineFormData({
      ...lineFormData,
      [name]: value,
    });
  };

  // here asshole
  const handleKeyDownForTypeAheadForLine = (e) => {
    const { placeholder, value } = e.target;
    if (!value) return;
    if (e.keyCode === 9) {
      if (placeholder === "Search Make") {
        const makeId = makeList?.find(({ make }) => make === value)?.make_id;
        setLineFormData({
          ...lineFormData,
          selectedMake: [{ make: value }],
          make: value,
          make_id: makeId,
        });
      }
      if (placeholder === "Search Asset Types") {
        const assetTypeValue = filteredAssetTypes?.find(
          ({ asset_type }) => asset_type === value,
        );
        setLineFormData({
          ...lineFormData,
          selectedAssetType: [{ asset_type: value }],
          asset_type: value,
          asset_type_id: assetTypeValue?.asset_type_id,
          asset_class_id: assetTypeValue?.class_id,
        });
      }
    }
  };

  const handleImageDelete = (imagePath) => {
    let obj = {
      photo_location: imagePath,
      type: "asset",
      type_id: formData.type_id.toString(),
    };
    try {
      deletePhotosToAssets(obj, {
        user_id: inputValues?.user_id?.toString() ?? "",
      }).then((res) => {
        setMessageData({
          message: "Images Delete Successfully",
          status: "success",
        });
        const photos = [...assetPhotos];
        const updatedPhotos = photos.filter(({ path }) => path !== imagePath);
        setAssetPhotosCount(updatedPhotos?.length);
        setAssetPhotos(updatedPhotos);
      });
    } catch (error) {
      throw error;
      // console.log('error', error);
    }
  };
  const handleNominatePhoto = (obj) => {
    setShowFullImage(false);
    let payload = {
      type: "asset",
      op: "nominate_status_update",
      type_id: formData.type_id,
      photo_location: obj?.path,
      photo_nominated: obj?.photo_nominated === 1 ? "0" : "1",
      asset_media_type: "photo",
    };
    try {
      postPhotosToAssets(payload).then((res) => {
        setMessageData({
          message: "Images Update Successfully",
          status: "success",
        });
        const photos = [...assetPhotos];
        const updatedNominatePhotos = photos.map((item) => {
          if (item.path === obj?.path) {
            // Toggle the value of photo_nominated property
            item.photo_nominated = item?.photo_nominated === 1 ? 0 : 1;
          }
          return item; // Return the updated item
        });

        setAssetPhotos(updatedNominatePhotos);
      });
    } catch (error) {
      throw error;
      // console.log('error', error);
    }
  };
  const handleCustomTypeAheadForMakeForLine = (makeValue) => {
    if (Array.isArray(makeValue)) return;
    setLineFormData({
      ...lineFormData,
      selectedMake: [{ make: makeValue }],
      make: makeValue,
    });
  };
  const handleSelectedForLines = (selected, type) => {
    let label;
    // we set lable in case we are adding a custom value and not one that is already in the list
    if (selected[0]?.label) label = true;
    switch (type) {
      // if we have a label, we set the values accordingly based on a ternary check
      case "make":
        setLineFormData({
          ...lineFormData,
          selectedMake: selected,
          make: label ? selected[0]?.label : selected[0]?.make,
          make_id: label ? null : selected[0]?.make_id,
        });
        break;
      case "assetType":
        setLineFormData({
          ...lineFormData,
          selectedAssetType: selected,
          asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
          asset_type_id: label ? null : selected[0]?.asset_type_id,
          asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
        });
        break;
      default:
        break;
    }
  };

  const handleSelectedForLineAndAssetTypeForLine = (selected) => {
    let label;
    if (selected[0]?.label) label = true;
    setLineFormData({
      ...lineFormData,
      selectedAssetType: selected,
      asset_type: label ? selected[0]?.label : selected[0]?.asset_type,
      asset_type_id: label ? null : selected[0]?.asset_type_id,
      asset_class_id: +selected[0]?.class_id ? +selected[0]?.class_id : 0,
    });
  };
  const handleInsertNewLine = async () => {
    if (
      lineFormData?.line_name ||
      lineFormData?.make ||
      lineFormData?.asset_type_id
    ) {
      let obj = {
        appraiser: formData?.appraiser,
        appraiser_note: lineFormData?.appraiser_note,
        asset_class_id: lineFormData?.asset_class_id,
        asset_no: lineFormData?.asset_no,
        asset_type_id: lineFormData?.asset_type_id,
        condition: lineFormData?.condition,
        cost: lineFormData?.cost,
        created_by: Number(userId),
        formAction: "saveUpdate",
        line_name: lineFormData?.line_name,
        line_text2: lineFormData?.line_text2,
        location_id: formData?.location_id
          ? formData?.location_id
          : lastAssetData?.locationsDataFull?.[0].location_id, // needs to be made dynamic most likely
        make: lineFormData?.make,
        make_id: lineFormData?.make_id,
        modified_by: Number(userId),
        priceVal: lineFormData.priceVal ? lineFormData.priceVal : {},
        project_id: lastAssetData?.locationsDataFull?.[0]?.project_id,
        project_id_crm: decodedProjectId,
        type: "line",
        year: lineFormData?.year,
      };
      // dispatch(postLineDetailAction(obj, 'addLine'));
      setLoading(true);
      await addNewLine(obj);
      setMessageData({
        message: "Line added successfully.",
        status: "success",
      });
      const linesByArea = await fetchLineByArea({
        area: formData.area,
        location_id: formData.location_id,
      });
      setFormFieldOptions((prevState) => ({
        ...prevState,
        lines: linesByArea,
      }));
      setLineFormData({
        condition: "Good",
        asset_class_id: 1,
        line_text2: ", Consisting of:",
      });
      setLoading(false);
      setShowLineAddModal(false);
    } else {
      setMessageData({
        message:
          "Please enter atleast one field from Make, Text1 or Asset Type.",
        status: "warning",
      });
    }
  };

  const handleImageEnter = (obj) => {
    setShowFullImage(true);
    setImagePath(obj);
  };

  const handleAdd = async () => {
    if (!addValue) {
      setMessageData({ message: "Please Enter Asset Type", status: "warning" });
      return;
    }
    let addObj = {
      created_by: Number(userId),
      modified_by: Number(userId),
      type: addValue,
      location_id: formData.location_id,
      project_id_crm: decodedProjectId,
      assetId: params?.id,
      action: "add",
    };
    const isAlreadyExists = formFieldOptions.assetTypeList?.find(
      ({ asset_type }) => asset_type === addValue,
    );
    if (isAlreadyExists) {
      setMessageData({
        message: "Asset Type Already Exists",
        status: "warning",
      });
    } else {
      await postAssetTypeAdd(addObj);
      setMessageData({
        message: "Asset Type added successfully.",
        status: "success",
      });
      const assetByLocationPayload = {
        project_id_crm: decodedProjectId,
        location_id: formData.location_id,
      };

      const assetDetailResponse = await getAssetDetailByLocation(
        assetByLocationPayload,
      );
      setFormFieldOptions({
        ...formFieldOptions,
        assetTypeList: assetDetailResponse.assetTypeList,
      });
      setShowAssetTypeModal(false);
    }
    setAddValue("");
  };

  // line logic
  return (
    <MyDiv>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box className="page_heading">
            <Box className="d-flex">
              <Typography>
                {formData.type_id ? "Edit Asset" : "Add Asset"}
              </Typography>
              <Box>
                {formData.type_id && params.id && (
                  <>
                    <Box className="d-flex project_head">
                      <Typography className="project_title" variant="h6">
                        Project:
                      </Typography>
                      <Typography className="project_title f-600" variant="h6">
                        {formFieldOptions?.projectName}
                      </Typography>
                    </Box>
                    <div>
                      <span className="submenu">
                        Created By: {Number(userId)}
                      </span>
                      <span className="submenu">
                        Created on: <b>{formData?.created_on}</b>
                      </span>
                      <span className="submenu">
                        Modified By: <b>{formData.modified_by}</b>
                      </span>
                      <span className="submenu">
                        Modified on: <b>{formData.modified_on}</b>
                      </span>
                      <span className="submenu">
                        Ref#: <b>{formFieldOptions?.currentRef}</b>
                      </span>
                      <span className="submenu">
                        Asset Id: <b>{formData?.asset_id}</b>
                      </span>
                    </div>
                  </>
                )}
              </Box>
            </Box>
            <Box className="d-flex">
              <Box>
                <IconButton
                  onClick={() => handleSubmit("prev")}
                  className="icon"
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleSubmit("list")}
                  className="list_btn"
                >
                  <FormatListBulletedIcon />
                </IconButton>
                {params?.id ? (
                  <IconButton
                    onClick={() => handleSubmit("next")}
                    className="icon"
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
          <Box className="wrapper_box">
            <Grid container spacing={2}>
              <Grid item md={3}>
                <Typography>Select Location</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    id="select_location"
                    name="location_id"
                    onChange={(e) => handleChange(e, "location")}
                    // disabled={localStorage.getItem('editMode')}
                    value={formData?.location_id}
                  >
                    {lastAssetData?.locationsDataFull?.map((item, index) => (
                      <StyledMenuItem key={index} value={item.location_id}>
                        {item.location_id} {item.name} [{item.street}{" "}
                        {item.city} {item.country}]
                      </StyledMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <Typography>Appraiser</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    id="select_appraiser"
                    name="appraiser"
                    onChange={handleChange}
                    value={formData?.appraiser}
                  >
                    {appraiserList?.map((item, index) => (
                      <StyledMenuItem key={index} value={item.user_id}>
                        {item.last_name} {item.name}
                      </StyledMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Box className="text_flex">
                    <Typography>Area</Typography>
                    <Typography className="note">Max 250 characters</Typography>
                  </Box>
                  {/*https://github.com/ericgio/react-bootstrap-typeahead/blob/main/docs/Filtering.md*/}
                  <Typeahead
                    id="basic-typeahead"
                    className="typeahead-fix"
                    allowNew
                    // clearButton
                    ref={areaRef}
                    labelKey={(option) => `${option.area}`}
                    filterBy={(option, props) => {
                      return option?.area
                        ?.toLowerCase()
                        .startsWith(props?.text.toLowerCase());
                    }}
                    options={formFieldOptions?.areas}
                    placeholder="Search Area"
                    aria-label="area"
                    selected={formData?.selectedArea} // we are expecting it in the format [{key:key_value, value: value}]
                    size="large"
                    onChange={(selected) => handleSelected(selected, "area")}
                    onInputChange={handleCustomTypeAheadForArea}
                    onKeyDown={handleKeyDownForTypeAhead}
                  />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Box className="d-flex add_btn">
                    <Box className="text_flex">
                      <Typography>
                        Line
                        {lineLoading && (
                          <CircularProgress color="info" size={20} />
                        )}
                      </Typography>

                      <Box className="d-flex">
                        <Typography className="note">
                          Max 500 characters
                        </Typography>
                        <Tooltip tabIndex={-1} title="Add New Line">
                          <IconButton
                            className="add_btn_icon"
                            area-label="Add New Line"
                            onClick={() => setShowLineAddModal(true)}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                  <Typeahead
                    className="typeahead-fix"
                    id="basic-typeahead"
                    // allowNew
                    // clearButton
                    ref={lineRef}
                    filterBy={(option, props) => {
                      return option?.line_name
                        ?.toLowerCase()
                        .startsWith(props?.text.toLowerCase());
                    }}
                    labelKey={(option) => `${option.line_name || ""}`}
                    options={formFieldOptions?.lines}
                    placeholder="Search Line"
                    // disabled={isfieldEnbled}
                    selected={formData?.selectedLine} // we are expecting it in the format [{key:key_value, value: value}]
                    size="large"
                    onChange={(selected) =>
                      handleSelectedForLineAndAssetType(selected, "line")
                    }
                    onKeyDown={handleKeyDownForTypeAhead}
                  />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Typography>Quantity</Typography>
                  <Box className="d-flex">
                    <FormControl
                      size="small"
                      fullWidth
                      className="form_control"
                    >
                      <Select
                        id="select_input"
                        className="select_value"
                        value={formData?.quantity_type}
                        onChange={handleChange}
                        name="quantity_type"
                        MenuProps={{ ...Menu }}
                      >
                        {quantity?.map((item, index) => {
                          return (
                            <StyledMenuItem key={index} value={item.type}>
                              {item.name}
                            </StyledMenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <CustomTextBox
                      type="number"
                      value={
                        formData?.quantity_type === "number"
                          ? formData?.quantity
                          : ""
                      }
                      onChange={handleChange}
                      name="quantity"
                      disabled={formData?.quantity_type === "lot"}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="d-flex">
                  <Box className="input_box w-50">
                    <Typography>Asset #</Typography>
                    <input
                      type="text"
                      // ref={assetHashRef}
                      value={formData?.asset_no}
                      onChange={handleChange}
                      autoFocus
                      name="asset_no"
                      style={{
                        height: 29,
                        width: "100%",
                      }}
                    />
                  </Box>
                  <Box className="input_box w-50">
                    <Typography>Condition</Typography>
                    <FormControl size="small" fullWidth>
                      <Select
                        id="select_input"
                        className="select_value"
                        value={formData?.condition}
                        onChange={handleChange}
                        name="condition"
                        MenuProps={{ ...Menu }}
                      >
                        {condition?.map((item, index) => {
                          return (
                            <StyledMenuItem key={index} value={item.name}>
                              {item.name}
                            </StyledMenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Typography>Make</Typography>
                  <Typeahead
                    className="typeahead-fix"
                    id="basic-typeahead"
                    allowNew
                    filterBy={(option, props) => {
                      return option?.make
                        ?.toLowerCase()
                        .startsWith(props?.text.toLowerCase());
                    }}
                    // clearButton
                    ref={makeRef}
                    labelKey={(option) => option?.make?.toString() || ""}
                    options={makeList}
                    placeholder="Search Make"
                    selected={formData?.selectedMake} // we are expecting it in the format [{key:key_value, value: value}]
                    size="large"
                    onChange={(selected) => handleSelected(selected, "make")}
                    onInputChange={handleCustomTypeAheadForMake}
                    onKeyDown={handleKeyDownForTypeAhead}
                  />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Typography>Model</Typography>
                  <Box className="d-flex">
                    <FormControl
                      size="small"
                      fullWidth
                      className="form_control"
                    >
                      <Select
                        id="select_input"
                        className="select_value"
                        value={formData?.model_label_id}
                        onChange={handleChange}
                        name="model_label_id"
                        MenuProps={{ ...Menu }}
                      >
                        {formFieldOptions?.modelLabelList?.map(
                          (item, index) => {
                            return (
                              <StyledMenuItem
                                key={index}
                                value={item.model_label_id}
                              >
                                {item.name}
                              </StyledMenuItem>
                            );
                          },
                        )}
                      </Select>
                    </FormControl>
                    <Box sx={{ width: "100%" }}>
                      {!MODEL_DATA && (
                        <CircularProgress color="info" size={20} />
                      )}
                      <Typeahead
                        id="basic-typeahead"
                        allowNew
                        // clearButton
                        className="typeahead-fix"
                        ref={modelRef}
                        filterBy={(option, props) => {
                          return option?.model
                            ?.toLowerCase()
                            .startsWith(props?.text.toLowerCase());
                        }}
                        labelKey={(option) => option?.model?.toString() || ""}
                        options={formFieldOptions.modelList}
                        placeholder="Search Model"
                        selected={formData?.selectedModel} // we are expecting it in the format [{key:key_value,` value: value}]
                        size="large"
                        onChange={(selected) =>
                          handleSelected(selected, "model")
                        }
                        onInputChange={handleCustomTypeAheadForModel}
                        onKeyDown={handleKeyDownForTypeAhead}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Typography>Capacity</Typography>
                  <Typeahead
                    className="typeahead-fix"
                    id="basic-typeahead"
                    allowNew
                    filterBy={(option, props) => {
                      return option?.capacity
                        ?.toLowerCase()
                        .startsWith(props?.text.toLowerCase());
                    }}
                    // clearButton
                    ref={capacityRef}
                    labelKey={(option) => `${option?.capacity}`}
                    options={formFieldOptions.capacityList}
                    placeholder="Search Capacity"
                    selected={formData?.selectedCapacity} // we are expecting it in the format [{key:key_value, value: value}]
                    size="large"
                    onChange={(selected) =>
                      handleSelected(selected, "capacity")
                    }
                    onInputChange={handleCustomTypeAheadForCapacity}
                    onKeyDown={handleKeyDownForTypeAhead}
                  />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Box className="d-flex add_btn">
                    <Typography>Asset Type</Typography>
                    <Tooltip tabIndex={-1} title="Add New Asset Type">
                      <IconButton
                        // onClick={handleDialog}
                        onClick={() => setShowAssetTypeModal(true)}
                      >
                        <Add />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typeahead
                    className="typeahead-fix"
                    id="basic-typeahead"
                    // allowNew
                    // clearButton
                    filterBy={(option, props) => {
                      return option?.asset_type
                        ?.toLowerCase()
                        .startsWith(props?.text.toLowerCase());
                    }}
                    ref={assetTypeRef}
                    labelKey={(option) => option?.asset_type?.toString() || ""}
                    options={formFieldOptions.assetTypeList} // remove 1st option select or search asset type
                    placeholder="Search Asset Types"
                    selected={formData?.selectedAssetType} // we are expecting it in the format [{key:key_value, value: value}]
                    size="large"
                    onChange={(selected) =>
                      handleSelectedForLineAndAssetType(selected, "assetType")
                    }
                    onKeyDown={handleKeyDownForTypeAhead}
                  />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Typography>Asset Class</Typography>
                  <FormControl size="small" fullWidth>
                    <Select
                      id="select_input"
                      className="select_value"
                      value={formData?.asset_class_id}
                      onChange={handleChange}
                      name="asset_class_id"
                      MenuProps={{ ...Menu }}
                    >
                      {/* <StyledMenuItem key={-1} value={0}>
                        Please Select
                      </StyledMenuItem> */}
                      {formFieldOptions?.assetClassList?.map((item, index) => {
                        return (
                          <StyledMenuItem
                            key={index}
                            value={item.asset_class_id}
                          >
                            {item.name}
                          </StyledMenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Typography>Serial Number</Typography>
                  <Box className="d-flex">
                    <FormControl
                      size="small"
                      fullWidth
                      className="form_control"
                    >
                      <Select
                        id="select_input"
                        className="select_value"
                        value={+formData?.serial_label_id}
                        onChange={handleChange}
                        name="serial_label_id"
                        MenuProps={{ ...Menu }}
                      >
                        {formFieldOptions.serialLabelList?.map(
                          (item, index) => {
                            return (
                              <StyledMenuItem
                                key={index}
                                value={item.serial_label_id}
                              >
                                {item.name}
                              </StyledMenuItem>
                            );
                          },
                        )}
                      </Select>
                    </FormControl>
                    <CustomTextBox
                      value={formData?.serial_number}
                      onChange={handleChange}
                      name="serial_number"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Typography>Description</Typography>
                  <CustomTextArea
                    minRows={2}
                    value={formData?.description}
                    name="description"
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Typography>Appraiser Notes</Typography>
                  <CustomTextArea
                    minRows={2}
                    value={formData?.appraiser_note}
                    name="appraiser_note"
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item md={1.5}>
                <Box className="input_box">
                  <Typography>Year</Typography>
                  <CustomTextBox
                    maxLength={4}
                    value={formData?.year}
                    onChange={handleChange}
                    name="year"
                  />
                </Box>
              </Grid>
              <Grid item md={1.5}>
                <Box className="input_box">
                  <Typography>Cost ($)</Typography>
                  <CustomTextBox
                    type="number"
                    value={formData?.cost}
                    onChange={handleChange}
                    name="cost"
                  />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="input_box">
                  <Typography>Mileage</Typography>
                  <Box className="d-flex">
                    <FormControl
                      size="small"
                      fullWidth
                      className="form_control"
                    >
                      <Select
                        id="select_input"
                        className="select_value"
                        value={formData?.mileage_type}
                        onChange={handleChange}
                        name="mileage_type"
                        MenuProps={{ ...Menu }}
                      >
                        {mileage?.map((item, index) => {
                          return (
                            <StyledMenuItem key={index} value={item.name}>
                              {item.name}
                            </StyledMenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <CustomTextBox
                      type="number"
                      value={formData?.mileage}
                      onChange={handleChange}
                      name="mileage"
                    />
                  </Box>
                </Box>
                <Box sx={{ zIndex: 0, position: "fixed" }}>
                  <FormControlLabel
                    className="checked_label"
                    control={
                      <Checkbox
                        name="flagged"
                        onChange={(e) => handleChange(e, "flagged")}
                        checked={formData?.flagged}
                      />
                    }
                    label="Flagged"
                  />
                  <FormControl>
                    <RadioGroup row>
                      <FormControlLabel
                        className="checked_label"
                        value="1"
                        control={
                          <Radio
                            onChange={(e) => handleChange(e, "found")}
                            checked={formData.is_not_found?.toString() === "1"}
                            // checked={true}
                          />
                        }
                        label="Found"
                      />
                      <FormControlLabel
                        className="checked_label"
                        value="2"
                        control={
                          <Radio
                            onChange={(e) => handleChange(e, "found")}
                            // checked={true}
                            checked={formData.is_not_found?.toString() === "2"}
                          />
                        }
                        label="Not Found"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={12} className="project_name d-flex">
                <Typography variant="h6">Price values allowed-</Typography>
                <Typography>
                  "No Value", "Not Valued", "Leased" or Numeric.
                </Typography>
              </Grid>
              <Grid container columnSpacing={{ sm: 2, md: 2 }}>
                <Grid item md={6}>
                  <Grid container columnSpacing={{ sm: 2, md: 2 }}>
                    {getPriceValues("asset")}
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Grid container columnSpacing={{ sm: 2, md: 2 }}>
                    <Grid item md={4}>
                      <Box className="input_box">
                        <Typography>Special Note</Typography>
                        <FormControl size="small" fullWidth>
                          <Select
                            id="select_input"
                            className="select_value"
                            // value={specialNotesSelectValue()}
                            value={
                              specialNote.includes(formData.special_notes)
                                ? formData.special_notes
                                : formData?.isCustomNoteMode
                                  ? "Create a New Note"
                                  : ""
                            }
                            onChange={handleChange}
                            name="special_notes"
                            MenuProps={{ ...Menu }}
                          >
                            {specialNote?.map((item, index) => {
                              return (
                                <StyledMenuItem key={index} value={item}>
                                  {item}
                                </StyledMenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      {formData.special_notes == "Create a New Note" ||
                      formData?.isCustomNoteMode ? (
                        <Box className="input_box">
                          <Typography>Notes</Typography>
                          <CustomTextBox
                            value={
                              formData?.special_notes == "Create a New Note"
                                ? null
                                : formData?.special_notes
                            }
                            onChange={handleChange}
                            name="special_notes"
                          />
                        </Box>
                      ) : null}
                    </Grid>
                    {formData.type_id ? (
                      <Grid item md={4}>
                        <Box className="d-flex-link">
                          <CustomButton
                            title="Map and Nominate Photos"
                            variant="contained"
                            className="btn_theme"
                            onClick={handleMapPhotos}
                          />
                        </Box>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                {assetPhotosCount > 0 ? (
                  <Grid className="imageBox">
                    Images Mapped
                    <hr />
                    <Grid container columnSpacing={{ md: 2 }}>
                      {assetPhotos?.map((obj, index) => {
                        return (
                          <Grid
                            container
                            xs={2}
                            item
                            key={index}
                            className="image_row"
                          >
                            <Box className="list_item">
                              <ListItem key={index} className="list">
                                <img
                                  src={url + obj?.path}
                                  alt={obj?.path.split("/").pop()}
                                  onClick={() => handleImageEnter(obj)}
                                />
                                {/* <ListItemText
                            primary={obj?.path.split('/').pop()}
                            className="label_name"
                          /> */}
                                <FormControlLabel
                                  className="checked_label"
                                  control={
                                    <Checkbox
                                      checked={
                                        obj?.photo_nominated === 1 ?? false
                                      }
                                      onChange={() => handleNominatePhoto(obj)}
                                    />
                                  }
                                  label="Nominate"
                                />
                                <IconButton
                                  sx={{
                                    color: "white",
                                    position: "absolute",
                                    right: "3px",
                                    top: "1px",
                                    background: "rgb(193 193 193 / 80%)",
                                    padding: "4px",
                                  }}
                                  onClick={() => handleImageDelete(obj?.path)}
                                >
                                  <Close />
                                </IconButton>
                              </ListItem>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Box className="d-flex btn-flex mt-10">
                <CustomButton
                  title="Save"
                  variant="contained"
                  className="btn_theme"
                  onClick={() => handleSubmit()}
                />
                <CustomButton
                  title="Save & Create New"
                  variant="contained"
                  className="btn_theme"
                  onClick={() => handleSubmit("saveCreateNew")}
                />
                <CustomButton
                  title="Save & Copy New"
                  variant="contained"
                  className="btn_theme"
                  onClick={() => handleSubmit("saveCopyNew")}
                />
                <CustomButton
                  title="Save & Copy Below"
                  variant="contained"
                  className="btn_theme"
                  onClick={() => handleSubmit("saveCopyBelow")}
                />
                <CustomButton
                  title="Cancel"
                  variant="contained"
                  className="btn_theme"
                  onClick={resetForm}
                />
                {formData.type_id ? (
                  // {!isaction ? (
                  <CustomButton
                    title="Delete"
                    variant="contained"
                    className="btn_theme"
                    onClick={() => handleSubmit("delete")}
                    // onClick={() => handleSubmit('Delete')}
                  />
                ) : (
                  ""
                )}
                <CustomButton
                  title="Back"
                  variant="contained"
                  className="btn_theme"
                  // onClick={handleBack}
                  onClick={() => saveBeforeMove("list")}
                />
              </Box>
            </Grid>
          </Box>
        </>
      )}
      <AlertMessage
        message={messageData?.message}
        severity={messageData?.status}
        open={messageData}
        close={() => setMessageData(null)}
      />
      <ConfirmationAlert
        openConfirmation={showDeleteConfirmation}
        handleConfirmation={() => setShowDeleteConfirmation(false)}
        title="Are you sure to delete this asset"
        handleDelete={handleDelete}
      />
      <InsertNewLineDialog
        openDialog={showLineAddModal}
        handleSelectedForLineAndAssetTypeForLine={
          handleSelectedForLineAndAssetTypeForLine
        }
        handleChange={handleLineFormChange}
        handleClose={() => {
          setShowLineAddModal(false);
          setLineFormData({
            condition: "Good",
            asset_class_id: 1,
            line_text2: ", Consisting of:",
          });
        }}
        formData={lineFormData}
        valueCodes={
          formFieldOptions.locationPriceValues.find(
            ({ value_short_name }) => value_short_name === name,
          )?.value_code
        }
        getPriceValues={() => getPriceValues("line")}
        condition={condition}
        makeList={makeList}
        handleSelected={handleSelectedForLines}
        handleCustomTypeAheadForMakeForLine={
          handleCustomTypeAheadForMakeForLine
        }
        handleInsertNewLine={handleInsertNewLine}
        handleKeyDownForTypeAheadForLine={handleKeyDownForTypeAheadForLine}
        assetTypes={formFieldOptions.assetTypeList}
        assetClassList={formFieldOptions.assetClassList}
      />
      <FullSizeImagePopUp
        url={url}
        imagePath={imagePath}
        openDialog={showFullImage}
        onClose={() => setShowFullImage(false)}
      />
      <AddAssetDialog
        handleDialog={() => {
          setShowAssetTypeModal(false);
          setAddValue(null);
        }}
        openDialog={showAssetTypeModal}
        addValue={addValue}
        handleChangeAdd={(e) => {
          e.target.value.length <= 40 ? setAddValue(e.target.value) : null;
        }}
        handleAdd={handleAdd}
      />
    </MyDiv>
  );
};

export default AddAssets;

/* phase 1
 get all the correct data on add asset page load
  steps -
    1 - api - location?getLastAsset=true&location_id=17638&project_id_crm=ee406332-ddfa-11ed-a4c4-0ad6b217f8b4
    2 - api - common?project_id_crm=ee406332-ddfa-11ed-a4c4-0ad6b217f8b4&type=getMakeCapacityList
    3 - api - area-by-location?location_id=17638
    4 - api - asset-detail?location_id=17638&project_id_crm=ee406332-ddfa-11ed-a4c4-0ad6b217f8b4
    5 - api - line-by-area?area=Undefined+Area&location_id=17638
*/

// 1 - maintain a variable that holds the value to show edit/add data in the header
// 2 - create a fuction for all three icon action (previous, list and next)
// 3 - gloabl values - PROJECTNAME
// 4 - for values to be maintained in state - location, appraiser, etc
// 5 - when area changes, we call a function to get the associated lines from the api, although no need when the location changes?
// 6 - add a loader for lines since we will be fetching them each time the area changes and want users to know we are fetching new lines
// 7 - if quantityType value is set to lot disable quantity field

// Loader Handling for Areas:
// When the user searches for or selects an area, initiate a loader to indicate that the data is being fetched, just like the one already implemented for fetching lines. This ensures a consistent user experience.

// Validation for Quantity Field:
// Add validation to ensure that the quantity field accepts only numeric values and is restricted to the max limit specified (6 digits), especially when quantityType is set to "number."

// Disable Appraiser Field Based on Condition:
// If condition_code == 'Y', disable the appraiser field, or conditionally show/hide the appraiser selection depending on the user's role or project stage.

// Dynamic Error Handling for Lines and Makes:
// When the angucomplete-alt autocomplete fields for lines, makes, or areas don't return valid results, display custom error messages such as "Line not found" or "Make not found."

// Field Reset on Location Change:
// When the location is changed, reset all dependent fields such as area and lines, as the fetched data will no longer be valid. Add a condition to ensure that unnecessary API calls are avoided.

// Restrict Serial Number Input Format:
// Add validation to ensure that the serial number field accepts only alphanumeric characters, as this field is likely to be crucial for tracking assets.

// State Management for Fields:
// Keep track of the current state of fields like location, area, line, and appraiser in $scope and reset them when transitioning between different assets, ensuring form consistency.

// Improved Asset Type Selection:
// Provide a tooltip or additional information when the user hovers over asset types to give them a better understanding of what each type represents.

// Modal for Asset Photos:
// Ensure that the modal for asset photos is appropriately linked to the selected asset and dynamically loads any changes in real-time after photos are added or deleted.

// Auto Save Warning:
// Implement a warning when the user attempts to navigate away from the form without saving changes, especially for critical fields like location, appraiser, and asset type.
